import ExerciseCard from "../components/teachers/ExerciseCard";
import { useEffect, useState } from 'react';
import * as consts from ".././consts/Consts";
import { createHeaders, fetchDraftTask, fetchFutureTask, fetchPastTask } from '../functions/apiService';
import { useNavigate } from 'react-router-dom';
import { handleUnauthorizedError } from '../functions/authUtils';

const DraftExercise = () => {
  
  const navigate = useNavigate();

  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDraftTask(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  if (!getData) {
    return null;
  }

  return (
    <>
       <div className="exercise-cards">
        {getData.map((item) => (
          <ExerciseCard data={item} key={item.id}/>
        ))}
      </div>
    </>
  );
};

export default DraftExercise;
