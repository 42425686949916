import CryptoJS from "crypto-js";

// Функция для генерации случайного nonce длиной 8 символов
export function generateRandomNonce(length) {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nonce = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    nonce += charset[randomIndex];
  }
  return nonce;
}

// Функция для создания MD5 подписи
export function generateSignature(datetime, nonce, salt, epochDivided) {
  const stringForHash = `${datetime}${nonce}${salt}${epochDivided}`;
  return CryptoJS.MD5(stringForHash).toString();
}

// Функция для получения текущего времени в формате ISO
export function getCurrentDatetime() {
  return new Date().toISOString().slice(0, 19).replace("T", " ");
}

// Функция для получения текущего времени в миллисекундах
export function getCurrentEpochDivided() {
  const currentEpoch = Date.now();
  return Math.floor(currentEpoch / 300000);
}

export const handleUnauthorizedError = (response, navigate) => {
  if (response.status === 401) {
    console.error("Неавторизован: Перенаправление на страницу логина");

    localStorage.removeItem("token");
    localStorage.removeItem("role");

    navigate("/");
  } else {
    console.error("Произошла ошибка:", response.statusText || response);
  }
};
