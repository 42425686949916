// export const API_URL = "https://api-performance.dualvet.az/api";
// export const COMPANY_NAME = "Performance";
// export const FAVICON_PATH = "/favicon.ico";
// export const LOGO_PATH_LOGIN = "../img/performance-logo_black.png";
// export const LOGO_PATH_NAVBAR = "../img/performance-logo_white.png";

let logos = {
    logo_white: "",
    logo_black: ""
  };
  
  export const setLogos = (newLogos) => {
    logos = { ...logos, ...newLogos };
  };
  
  export const getLogos = () => logos;

export const API_URL = "https://api-performance.dualvet.az/api";
export const API_URL_IMG = "https://api-performance.dualvet.az";
export const COMPANY_NAME = "Bildungswerk der Baden";
// export const COMPANY_NAME = "Bildungswerk der Baden";
export const FAVICON_PATH = "../img/favicon.ico";
export const LOGO_PATH_LOGIN = "../img/logo-white-dualvet.svg";
export const LOGO_PATH_NAVBAR = "../img/logo-black-dualvet.svg ";