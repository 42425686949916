import { useNavigate } from "react-router-dom";

// Хук для получения функции навигации
const useNavigation = () => useNavigate();

// Установка токена в localStorage
const setToken = (tokenData) => {
  localStorage.setItem("token", JSON.stringify(tokenData));
};

// Получение токена из localStorage
const getToken = () => {
  const tokenDataString = localStorage.getItem("token");
  try {
    const tokenData = JSON.parse(tokenDataString);
    return tokenData ? tokenData : null;
  } catch (error) {
    return null;
  }
};

// Сохранение токена в localStorage
const saveToken = (token) => {
  setToken({
    value: token,
  });
};

// Обработка неавторизованного доступа
const handleUnauthorizedResponse = (navigate) => {
  navigate("/");
};

// Проверка токена и навигация в случае отсутствия токена
const checkTokenAndNavigate = (navigate) => {
  const token = getToken();
  if (!token) {
    handleUnauthorizedResponse(navigate);
  }
};

// Очистка токена из localStorage
const clearToken = () => {
  localStorage.removeItem("token");
};

export {
  saveToken,
  getToken,
  clearToken,
  handleUnauthorizedResponse,
  useNavigation,
  checkTokenAndNavigate,
};
