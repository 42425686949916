import React from "react";
import { Col } from "react-bootstrap";

function MyGroups({ getData }) {
  if (!getData || !Array.isArray(getData.groups) || getData.groups.length === 0) {
    return <p>Hazırda qrupunuz yoxdur.</p>;
  }

  return (
    <>
      {getData.groups.map((group) => (
        <Col key={group.id} md={6} className="mb-4">
          <div className="user__groups-item">
            <p className="user__groups-date">Başlanma tarixi: {group.start_date}</p>
            <h4 className="user__groups-name">{group.name}</h4>
            {/* <p className="user__groups-code">{group.code}</p> */}
          </div>
        </Col>
      ))}
    </>
  );
}

export default MyGroups;
