import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import StudentTableItem from "./StudentTableItem";
import { studentTableHeading, studentTableSelect } from "../../utils/data";
import AddStudent from "./AddStudent";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchStudents } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function StudentTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('full_name');  
  const [sortOrder, setSortOrder] = useState('asc'); 

  const handleModalShowAdd = () => setModalShowAdd(true);
  const handleModalCloseAdd = () => setModalShowAdd(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStudents(navigate, perPage, currentPage, search, sortField, sortOrder);
        setGetData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [perPage, currentPage, search, navigate, sortField, sortOrder]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  const handleSortChange = (value) => {
    const [field, order] = value.split(' '); // e.g., "full_name asc" or "email desc"
    setSortField(field);
    setSortOrder(order);
  };


  return (
    <>
      <AddStudent show={modalShowAdd} handleClose={handleModalCloseAdd} />
      <div className="groups">
        <div className="groups-header">
          <button className="add-button" onClick={handleModalShowAdd}>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.5"
                y="8"
                width="13"
                height="13"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 8V1H1.5V16H8.5"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M18 14.5H12M15 11.5V17.5"
                stroke="#141B34"
                strokeWidth="1.5"
              />
            </svg>
            <div>Yeni tələbə əlavə et</div>{" "}
          </button>

          <div className="header-right">
          <FilterSelect data={studentTableSelect} onSelect={handleSortChange} />
          <div className="search-box">
            <input 
                type="text" 
                placeholder="Axtar" 
                value={search}
                onChange={handleSearchChange} 
              />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {studentTableHeading.map((item, i) => (
                 <th key={item.value}>
                 <div className={`${i === 0 ? "left" : ""} column-content`}>
                   {item.name}
                   {sortField === item.value && (
                     <span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
                   )}
                 </div>
               </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {getData &&
                getData.map((student, i) => (
                  <StudentTableItem
                    data={student}
                    key={i}
                    handleModalShowAdd={handleModalShowAdd}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />
          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default StudentTable;
