import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SucessModal = ({ show, handleClose }) => {
  const navigate = useNavigate();

  const handleCloseAndRefresh = () => {
    handleClose();
    navigate("/grade");
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="student-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="alert-modal">
            <div className="alert-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 72 72"
                color="#399918"
                fill="none"
              >
                <circle
                  cx="36"
                  cy="36"
                  r="34.1667"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                />
                <path
                  d="M24 36L32 44L48 28"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div className="alert-message">
              <h2 className="alert-title">Uğurlu əməliyyat!</h2>

              <p className="alert-content">Əməliyyat uğurla icra olundu!</p>

              <div className="buttons mt-3">
                <Button
                  type="button"
                  className="yes-button"
                  onClick={handleCloseAndRefresh}
                >
                  Bağla
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SucessModal;
