import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import EditGroup from "./EditGroup";
import DeleteModal from "./DeleteModal";
import { Link } from "react-router-dom";

function GroupsTableItem({ setLoading, data }) {
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const handleModalShowEdit = () => setModalShowEdit(true);
  const handleModalCloseEdit = () => setModalShowEdit(false);
  const handleModalCloseDelete = () => setModalShowDelete(false);
  const handleModalOpenDelete = () => setModalShowDelete(true);

  const renderSchedule = () => {
    return (
      <table className="turn-wrapper">
        <tbody>
          {data.schedules?.map((schedule, index) => (
            <tr key={index}>
              <td>
                <span className="turn-left">{schedule.week_day}</span>
              </td>
              <td>
                <span className="turn-right">{schedule.time}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <EditGroup
        show={modalShowEdit}
        handleClose={handleModalCloseEdit}
        id={data.id}
        data={data}
      />
      <DeleteModal
        show={modalShowDelete}
        handleClose={handleModalCloseDelete}
        data={data}
        apiPath={"group"}
      />
      <tr>
        <td>
          <div className="left column-content">{data.start_date}</div>
        </td>
        <td>
          <div className="column-content">{data.name}</div>
        </td>
        <td>
          <div className="column-content">
            {data.teacher ? data.teacher.full_name : "Müəllim seçilmədi"}
          </div>
        </td>
        <td>
          <div className="column-content">
            <OverlayTrigger
              placement="right"
              rootClose
              trigger="click"
              overlay={
                <Popover id="popover-positioned-right">
                  <Popover.Body style={{ padding: "5px" }}>
                    {renderSchedule()}
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="d-inline-block">
                <button className="operation-btn gray-btn text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    color="#fff"
                    fill="none"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </span>
            </OverlayTrigger>
          </div>
        </td>
        <td>
          <div className="column-content">{data.type}</div>
        </td>
        <td>
          <div className="column-content">{data.subject.name}</div>
        </td>
        <td>
          <div className="operation-buttons right column-content">
            <Link className="blue-btn operation-btn" to={`/group-details/${data.id}`} onClick={() => setLoading(true)} // Loading state'i burada ayarlayın
            >
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11C12.6569 11 14 9.65685 14 8Z"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <path
                  d="M11 1C16.5228 1 21 8 21 8C21 8 16.5228 15 11 15C5.47715 15 1 8 1 8C1 8 5.47715 1 11 1Z"
                  stroke="white"
                  strokeWidth="1.5"
                />
              </svg>
            </Link>
            <button
              className="green-btn operation-btn"
              onClick={handleModalShowEdit}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.9893 15.8934L6.96677 15.0979C7.11197 15.0689 7.24532 14.9975 7.35002 14.8928L12.3374 9.90543C12.6303 9.61254 12.6303 9.13766 12.3374 8.84477L9.15539 5.66279C8.86249 5.3699 8.38761 5.3699 8.09472 5.66279L3.10738 10.6502C3.00268 10.7549 2.93131 10.8882 2.90227 11.0334L2.10678 15.0109C2.00182 15.5357 2.46451 15.9984 2.9893 15.8934Z"
                  fill="white"
                />
                <path
                  d="M15.1444 3.91645L14.0837 2.85579C13.2051 1.97711 11.7804 1.97711 10.9018 2.85579L10.1628 3.59475C9.86991 3.88764 9.86991 4.36252 10.1628 4.65541L13.3448 7.83739C13.6377 8.13029 14.1125 8.13028 14.4054 7.83739L15.1444 7.09843C16.0231 6.21975 16.0231 4.79513 15.1444 3.91645Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              className="red-btn operation-btn"
              onClick={() => handleModalOpenDelete(data.name)}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.16667 9.16675C9.67293 9.16675 10.0833 9.57715 10.0833 10.0834V14.6667C10.0833 15.173 9.67293 15.5834 9.16667 15.5834C8.66041 15.5834 8.25 15.173 8.25 14.6667V10.0834C8.25 9.57715 8.66041 9.16675 9.16667 9.16675Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8334 9.16675C13.3397 9.16675 13.7501 9.57715 13.7501 10.0834V14.6667C13.7501 15.173 13.3397 15.5834 12.8334 15.5834C12.3272 15.5834 11.9167 15.173 11.9167 14.6667V10.0834C11.9167 9.57715 12.3272 9.16675 12.8334 9.16675Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.16658 1.83325C7.6478 1.83325 6.41658 3.06447 6.41658 4.58325H3.66659H2.74992C2.24366 4.58325 1.83325 4.99366 1.83325 5.49992C1.83325 6.00618 2.24366 6.41658 2.74992 6.41658H3.66659V17.4166C3.66659 18.9354 4.8978 20.1666 6.41658 20.1666H15.5833C17.102 20.1666 18.3333 18.9354 18.3333 17.4166V6.41658H19.2499C19.7562 6.41658 20.1666 6.00618 20.1666 5.49992C20.1666 4.99366 19.7562 4.58325 19.2499 4.58325H18.3333H15.5833C15.5833 3.06447 14.352 1.83325 12.8333 1.83325H9.16658ZM13.7499 4.58325C13.7499 4.07699 13.3395 3.66659 12.8333 3.66659H9.16658C8.66032 3.66659 8.24992 4.07699 8.24992 4.58325H13.7499ZM6.41658 6.41658H5.49992V17.4166C5.49992 17.9228 5.91032 18.3333 6.41658 18.3333H15.5833C16.0895 18.3333 16.4999 17.9228 16.4999 17.4166V6.41658H15.5833H6.41658Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default GroupsTableItem;
