function TeachersActivityTableItem({ data }) {
  return (
    <>
      <tr>
        <td>
          <div className="left column-content">
            <img src={data.image} alt="" className="avatar-image" />
          </div>
        </td>
        <td>
          <div className="column-content">{data.name}</div>
        </td>
        <td>
          <div className="column-content">{data.time}</div>
        </td>
        <td>
          <div className="column-content right">{data.activity}</div>
        </td>
      </tr>
    </>
  );
}

export default TeachersActivityTableItem;
