import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InputMask from "react-input-mask";
import UserProfile from "../UserProfile";

function ProfileInfoForm({ formData, errors, handleChange }) {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(formData.image || "");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      handleUploadImage();
    }
  }, [imageUrl]);

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const formatDateForSubmission = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const formattedBirthDay = formData.birth_day
    ? formatDateForInput(formData.birth_day)
    : "";

  const handleDateChange = (e) => {
    const formattedDate = formatDateForSubmission(e.target.value);
    handleChange({ target: { name: "birth_day", value: formattedDate } });
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imgData = new FormData();
      imgData.append("file", selectedFile);
      imgData.append("folder", "profile");

      await uploadFile(imgData);
    }
  };

  const uploadFile = async (imgData) => {
    setIsLoading(true);
    let headers = createHeaders();
    delete headers["Content-Type"];

    try {
      const response = await fetch(`${consts.API_URL}/uploader`, {
        method: "POST",
        headers: headers,
        body: imgData,
      });

      const result = await response.json();

      if (response.ok) {
        setImageUrl(`${consts.API_URL_IMG}/${result.data.path}`);
        setTimeout(async () => {
          await handleUploadImage();
        }, 0);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadImage = async () => {
    if (!imageUrl) {
      return;
    }
    const headers = createHeaders();
    const apiPathImage =
      formData.role === "teacher"
        ? `${consts.API_URL}/teacher/profile/image/update`
        : `${consts.API_URL}/admin/profile/image/update`;

    try {
      const profileResponse = await fetch(apiPathImage, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ image: imageUrl }),
      });

      const profileData = await profileResponse.json();
      console.log("img", imageUrl);
      
      if (profileResponse.ok) {
      } else {
      }
    } catch (error) {
      handleUnauthorizedError(error, navigate);
    }
  };

  const handleDelete = async () => {
    const apiPathDelete =
      formData.role === "teacher"
        ? `${consts.API_URL}/teacher/profile/image/remove`
        : `${consts.API_URL}/admin/profile/image/remove`;

    try {
      const headers = createHeaders();
      const response = await fetch(apiPathDelete, {
        method: "DELETE",
        headers,
      });
      if (response.ok) {
        window.location.reload();
      } else {
        // Handle errors if necessary
      }
    } catch (error) {
      handleUnauthorizedError(error, navigate);
    }
  };

  return (
    <form className="edit-profile-password-form">
      <div className="pi-content-img">
        <div className="pi-c-image">
          {imageUrl ? (
            <img src={imageUrl} alt="Profile" />
          ) : formData.image ? (
            <img src={formData.image} alt="Profile" />
          ) : (
            <UserProfile imageSize={80} containerSize={96} />
          )}
        </div>
        <div className="buttons">
          <div className="file-input">
            <input
              type="file"
              name="image"
              id="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1Z"
                  fill="#101110"
                />
              </svg>
              <span>Yeni foto yüklə</span>
            </label>
          </div>
          <button type="button" className="outlined-btn" onClick={handleDelete}>
            Sil
          </button>
        </div>
      </div>
      <div className="pi-form-wrapper">
        <div className="pi-form-inner">
          <label htmlFor="full_name">Ad və Soyad</label>
          <input
            type="text"
            id="full_name"
            className={`profile-input secondary-input ${
              errors.full_name ? "border-red" : ""
            }`}
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
          />
          {errors.full_name && (
            <span className="validation-error">{errors.full_name}</span>
          )}
        </div>

        <div className="pi-form-inner">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            readOnly
            id="email"
            className={`profile-input secondary-input ${
              errors.email ? "border-red" : ""
            }`}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <span className="validation-error">{errors.email}</span>
          )}
        </div>

        <div className="pi-form-inner">
          <label htmlFor="phone">Mobil nömrə</label>
          <InputMask
            mask="+999 99 999 99 99"
            value={formData.phone}
            name="phone"
            onChange={handleChange}
            maskChar={null}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="text"
                id="phone"
                className={`profile-input secondary-input ${
                  errors.phone ? "border-red" : ""
                }`}
              />
            )}
          </InputMask>
          {errors.phone && (
            <span className="validation-error">{errors.phone}</span>
          )}
        </div>

        <div className="pi-form-inner">
          <label htmlFor="birth_day">Doğum tarixi</label>
          <input
            type="date"
            id="birth_day"
            className={`profile-input secondary-input ${
              errors.birth_day ? "border-red" : ""
            }`}
            name="birth_day"
            value={formattedBirthDay}
            onChange={handleDateChange}
          />
          {errors.birth_day && (
            <span className="validation-error">{errors.birth_day}</span>
          )}
        </div>

        <div className="pi-form-inner">
          <label htmlFor="github">Github</label>
          <input
            type="text"
            id="github"
            className="profile-input secondary-input"
            name="github"
            onChange={handleChange}
            value={formData.github}
          />
        </div>

        <div className="pi-form-inner">
          <label htmlFor="linkedin">LinkedIn</label>
          <input
            type="text"
            id="linkedin"
            className="profile-input secondary-input"
            name="linkedin"
            onChange={handleChange}
            value={formData.linkedin}
          />
        </div>
      </div>
    </form>
  );
}

export default ProfileInfoForm;
