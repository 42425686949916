export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_DATA_STATUS = 'SET_DATA_STATUS';

export const SET_GET_DATA = 'SET_GET_DATA';

export const setGetData = (data) => ({
  type: SET_GET_DATA,
  payload: data,
});

export const setDataStatus = (dataStatus) => ({
  type: SET_DATA_STATUS,
  payload: dataStatus,
});  
export const setSelectedGroup = (groupId) => ({
  type: SET_SELECTED_GROUP,
  payload: groupId,
});  

