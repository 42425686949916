import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleResetPassword = (e) => {
    e.preventDefault();

    if (!email) {
      setError("Email tələb olunur");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Düzgün email daxil edin");
    }
  };

  return (
    <>
      <section className="login">
        <Container fluid>
          <Row>
            <Col md={6} className="login__left">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="login__logo mb-5">
                  <img src="/img/logo.png" alt="" />
                </div>
                <Form onSubmit={handleResetPassword} style={{ width: "300px" }}>
                  <p className="text-center login-left__title mb-5">
                    Xoş gördük! Məlumatlarınızı yazaraq daxil olun.
                  </p>

                  {error && <Alert variant="danger">{error}</Alert>}

                  <Form.Group controlId="formLogin">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="performans@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100 login__btn"
                  >
                    <Link to="/reset-password" className="w-100">
                      Şifrəni sıfırla
                    </Link>
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6} className="login__right">
              <div className="login__background">
                <div className="h-100">
                  <div className="login__top">
                    <div className="img-container_login">
                      <div className="login__img login__img-back">
                        <img src="/img/iphone_2.png" alt="" />
                      </div>
                      <div className="login__img login__img-front">
                        <img src="/img/iphone_1.png" alt="" />
                      </div>
                    </div>

                    <div className="login__qr">
                      <div className="login-qr__img">
                        <img src="/img/qr.png" alt="" />
                      </div>
                      <p className="login-info">
                        QR kodu oxudaraq tətbiqi yükləyin
                      </p>
                    </div>
                  </div>
                  <div className="login__bottom">
                    <h1>Performance App</h1>
                    <p className="login__text">
                      iOS və ya Play Market vasistəsilə Coders Performans mobil
                      tətbiqini yükləyib təhsilinizlə bağlı bütün prosesləri
                      istədiyiniz yerdən izləyə bilərsiniz.
                    </p>
                    <span className="icon-btns"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgetPassword;
