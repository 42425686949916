import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import UserProfile from "../UserProfile";

function UserInfo() {
  const navigate = useNavigate();

  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAboutMe(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="user__top  mb-5">
      <div className="user__img">
        {getData.image ? (
          <img
            src={getData.image}
            alt=""
            className="profile-avatar-image profile-image-size"
          />
        ) : (
          <UserProfile imageSize={90} containerSize={110} />
        )}
      </div>
      <div className="user__info">
        <h3 className="user__name">{getData.full_name}</h3>
        <p className="user__email">{getData.email}</p>
        <p className="user__group">Qrup:Q-FE150223A-FS</p>
        <button className="user__lesson mt-3" onClick={handleShow}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.75 8.2501L15.9 2.1001"
              stroke="#98ABFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 5.1V1.5H12.9"
              stroke="#98ABFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75"
              stroke="#98ABFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>ONLAYN DƏRSƏ QOŞUL</span>
        </button>
      </div>
    </div>
  );
}

export default UserInfo;
