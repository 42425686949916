import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { createHeaders, fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";


function OnlineLessonModal({ show, handleClose }) {
  const [formData, setFormData] = useState({
    group_id: "",
    link: "",
  });

  const [errors, setErrors] = useState({});
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAboutMe(navigate);
        setGroups(data.groups);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);


  const resetFormData = () => {
    setFormData({
      group_id: "",
      online_lesson_link: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.group_id) {
      newErrors.group_id = "Dərsin mövzunu daxil edin";
    }

    if (!formData.online_lesson_link) {
      newErrors.online_lesson_link = "Dərsin linkini daxil edin";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    const headers = createHeaders()
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(`${consts.API_URL}/teacher/group/online-lesson-link`, { 
          method: "POST",
        headers: headers,
          body: JSON.stringify({
            group_id: formData.group_id,
            online_lesson_link: formData.online_lesson_link,
          }),
        });
        const data = await response.json();
        if (response.ok) {
          resetFormData();
          handleClose();
        } else {          
          if (data.errors && data.errors.online_lesson_link && data.errors.online_lesson_link.includes("The online lesson link field must be a valid URL.")) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              online_lesson_link: "URL düzgün deyil.",
            }));
          } else {
            console.error("Error adding Student:", data.message, data.errors);
          }
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };
  

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal} className="note-modal">
      <Modal.Header closeButton onHide={handleCloseModal}>
        <Modal.Title className="online-title">Onlayn Dərs Yarat</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="subject" className="select-form mb-4">
            <Form.Label>Qrup</Form.Label>
            <div className="position-relative">
              <Form.Control
                as="select"
                name="group_id"
                value={formData.group_id}
                onChange={handleChange}
                isInvalid={!!errors.group_id}
                className={errors.group_id ? "input-error" : ""}
              >
                <option value="">Qrup seçin</option>
                {groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L13 1"
                  stroke="#6F767E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <Form.Control.Feedback type="invalid">
              {errors.group_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="online_lesson_link">
            <Form.Label>Linki əlavə edin</Form.Label>
            <Form.Control
              as="textarea"
              rows={7}
              name="online_lesson_link"
              onChange={handleChange}
              value={formData.online_lesson_link}
              isInvalid={!!errors.online_lesson_link}
              className={errors.online_lesson_link ? "input-error" : ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.online_lesson_link}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="note-btn modal-btn btn-green">
            Online dərsi yarat
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default OnlineLessonModal;
