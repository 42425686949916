import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});

  const handleResetPassword = (e) => {
    e.preventDefault();

    let errors = {};

    let minLengthRegex = /^.{8,}$/;
    let leastUppercase = /(?=.*[A-Z])/;
    let leastLowercase = /(?=.*[a-z])/;
    let leastDigit = /(?=.*\d)/;

    if (newPassword.length === 0) {
      errors.newPassword = "Şifrə boş olmamalıdır.";
    } else {
      let messages = [];
      if (!minLengthRegex.test(newPassword)) {
        messages.push("minimum 8 simvoldan");
      }
      if (!leastUppercase.test(newPassword)) {
        messages.push("1 böyük hərfdən");
      }
      if (!leastLowercase.test(newPassword)) {
        messages.push("1 kiçik hərfdən");
      }
      if (!leastDigit.test(newPassword)) {
        messages.push("1 rəqəmdən");
      }
      if (messages.length > 0) {
        errors.newPassword = `Şifrə ${messages.join(", ")} ibarət olmalıdır.`;
      } else {
        errors.newPassword = "";
      }
    }

    if (confirmPassword.length === 0) {
      errors.confirmPassword = "Şifrə yenidən daxil olunmalıdır.";
    } else if (confirmPassword !== newPassword) {
      errors.confirmPassword = "Şifrə düzgün deyil.";
    } else if (confirmPassword === newPassword) {
      errors.confirmPassword = "";
    }

    setError(errors);
  };

  return (
    <>
      <section className="login">
        <Container fluid>
          <Row>
            <Col md={6} className="login__left">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="login__logo mb-5">
                  <img src="/img/logo.png" alt="" />
                </div>
                <Form onSubmit={handleResetPassword} style={{ width: "300px" }}>
                  <p className="text-center login-left__title mb-5">
                    Xoş gördük! Məlumatlarınızı yazaraq daxil olun.
                  </p>

                  <Form.Group controlId="formLogin" className="mb-3" id="form-input">
                    <Form.Label>Yeni şifrə</Form.Label>
                    <Form.Control
                      type="text"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className={`${error.newPassword ? "input-error" : ""}`}
                    />

                    {error.newPassword && (
                      <div className="text-danger">{error.newPassword}</div>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formLogin" id="form-input">
                    <Form.Label>Yeni şifrənin təkrarı</Form.Label>
                    <Form.Control
                      type="text"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={`${error.confirmPassword ? "input-error" : ""}`}
                    />
                    {error.confirmPassword && (
                      <div className="text-danger">{error.confirmPassword}</div>
                    )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100 login__btn"
                  >
                    Şifrəni sıfırla
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6} className="login__right">
              <div className="login__background">
                <div className="h-100">
                  <div className="login__top">
                    <div className="img-container_login">
                      <div className="login__img login__img-back">
                        <img src="/img/iphone_2.png" alt="" />
                      </div>
                      <div className="login__img login__img-front">
                        <img src="/img/iphone_1.png" alt="" />
                      </div>
                    </div>

                    <div className="login__qr">
                      <div className="login-qr__img">
                        <img src="/img/qr.png" alt="" />
                      </div>
                      <p className="login-info">
                        QR kodu oxudaraq tətbiqi yükləyin
                      </p>
                    </div>
                  </div>
                  <div className="login__bottom">
                    <h1>Performance App</h1>
                    <p className="login__text">
                      iOS və ya Play Market vasistəsilə Coders Performans mobil
                      tətbiqini yükləyib təhsilinizlə bağlı bütün prosesləri
                      istədiyiniz yerdən izləyə bilərsiniz.
                    </p>
                    <span className="icon-btns"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
