import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { NavLink } from "react-router-dom";

const MySidebarStudent = () => {
  const { collapseSidebar, collapsed } = useProSidebar();

  return (
    <Sidebar
      collapsed={collapsed}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        width: collapsed ? "80px" : "250px",
        background: "#fff",
      }}
    >
      <div style={{ flex: 1, background: "#fff" }}>
        <Menu>
          <MenuItem>
            <NavLink to="/teacher">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 10H5C7.20914 10 9 11.7909 9 14V16C9 18.2091 7.20914 20 5 20H4C1.79086 20 0 18.2091 0 16V14C0 11.7909 1.79086 10 4 10ZM4 12C2.89543 12 2 12.8954 2 14V16C2 17.1046 2.89543 18 4 18H5C6.10457 18 7 17.1046 7 16V14C7 12.8954 6.10457 12 5 12H4ZM3.5 0C5.433 0 7 1.567 7 3.5V4.5C7 6.433 5.433 8 3.5 8C1.567 8 0 6.433 0 4.5V3.5C0 1.567 1.567 0 3.5 0ZM3.5 2C2.67157 2 2 2.67157 2 3.5V4.5C2 5.32843 2.67157 6 3.5 6C4.32843 6 5 5.32843 5 4.5V3.5C5 2.67157 4.32843 2 3.5 2ZM15 9H16C18.2091 9 20 10.7909 20 13V16C20 18.2091 18.2091 20 16 20H15C12.7909 20 11 18.2091 11 16V13C11 10.7909 12.7909 9 15 9ZM15 11C13.8954 11 13 11.8954 13 13V16C13 17.1046 13.8954 18 15 18H16C17.1046 18 18 17.1046 18 16V13C18 11.8954 17.1046 11 16 11H15ZM12.5 0H16.5C18.433 0 20 1.567 20 3.5C20 5.433 18.433 7 16.5 7H12.5C10.567 7 9 5.433 9 3.5C9 1.567 10.567 0 12.5 0ZM12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5H16.5C17.3284 5 18 4.32843 18 3.5C18 2.67157 17.3284 2 16.5 2H12.5Z"
                  fill="#92929D"
                />
              </svg>
              {!collapsed && <span>Əsas</span>}
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/grade">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0H15C18.866 0 22 3.13401 22 7V15C22 18.866 18.866 22 15 22H7C3.13401 22 0 18.866 0 15V7C0 3.13401 3.13401 0 7 0ZM7 2C4.23858 2 2 4.23858 2 7V15C2 17.7614 4.23858 20 7 20H15C17.7614 20 20 17.7614 20 15V7C20 4.23858 17.7614 2 15 2H7ZM7.69164 10.2784L9.96275 12.5203L14.2178 7.36355C14.5693 6.93756 15.1996 6.87718 15.6256 7.22868C16.0516 7.58018 16.1119 8.21046 15.7604 8.63645L10.8096 14.6364C10.4357 15.0895 9.75378 15.1243 9.33576 14.7117L6.28661 11.7018C5.89356 11.3138 5.88946 10.6806 6.27745 10.2876C6.66544 9.89453 7.29859 9.89043 7.69164 10.2784Z"
                  fill="#92929D"
                />
              </svg>
              <span>Qiymətlər</span>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/exercises">
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 2V1C16 0.447715 16.4477 0 17 0C17.5523 0 18 0.447715 18 1V2H19C20.6569 2 22 3.34315 22 5V8V10V15C22 18.3137 19.3137 21 16 21H6C2.68629 21 0 18.3137 0 15V10V8V5C0 3.34315 1.34315 2 3 2H5V1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V2H16ZM2 8H20V5C20 4.44772 19.5523 4 19 4H18C18 4.55228 17.5523 5 17 5C16.4477 5 16 4.55228 16 4H7C7 4.55228 6.55228 5 6 5C5.44772 5 5 4.55228 5 4H3C2.44772 4 2 4.44772 2 5V8ZM2 10V15C2 17.2091 3.79086 19 6 19H16C18.2091 19 20 17.2091 20 15V10H2Z"
                  fill="#92929D"
                />
              </svg>
              <span>Tapşırıqlar</span>
            </NavLink>
          </MenuItem>
        </Menu>
      </div>
      <div>
        <button
          onClick={() => collapseSidebar()}
          className="btn btn-light"
          style={{
            border: "none",
            background: "transparent",
            fontSize: "24px",
          }}
        >
          {collapsed ? (
            <div className="sidebar-btn">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.76001 10.965L10.68 9.045L8.76001 7.125"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9.04492H10.6275"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 3C12.315 3 15 5.25 15 9C15 12.75 12.315 15 9 15"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="sidebar-btn">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.23999 7.035L7.31999 8.955L9.23999 10.875"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 8.95508L7.3725 8.95508"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 15C5.685 15 3 12.75 3 9C3 5.25 5.685 3 9 3"
                  stroke="#98ABFF"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </button>
      </div>
    </Sidebar>
  );
};

export default MySidebarStudent;
