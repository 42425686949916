const ScoreCard = () => {
  return (
    <>
      <div className="score-card">
        <div className="sc_icon">
          <div className="icon-inner">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 11.25C11.7959 11.25 14.0625 9.06739 14.0625 6.375C14.0625 3.68261 11.7959 1.5 9 1.5C6.20406 1.5 3.9375 3.68261 3.9375 6.375C3.9375 9.06739 6.20406 11.25 9 11.25Z"
                stroke="#101110"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.63999 10.14L5.63251 15.675C5.63251 16.35 6.10501 16.68 6.69001 16.4025L8.7 15.45C8.865 15.3675 9.1425 15.3675 9.3075 15.45L11.325 16.4025C11.9025 16.6725 12.3825 16.35 12.3825 15.675V10.005"
                stroke="#101110"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="sc_score">
          <div className="score-content">
            <div>
              <span className="score-text">80</span>
              /100
            </div>

            <p className="score-subtitle">Həftəlik performans</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScoreCard;
