import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSettingsGrade } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import EvaluateTableItem from "./EvaluateTableItem";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";

const EvaluateTable = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState({});
  const [percents, setPercents] = useState({
    daily: 0,
    quiz: 0,
    project: 0,
    exam: 0,
  });


  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSettingsGrade(navigate);
        setGetData(data);
        setPercents({
          daily: data.daily_percent,
          quiz: data.quiz_percent,
          project: data.project_percent,
          exam: data.exam_percent,
        });
        setTotal(
          data.daily_percent +
            data.quiz_percent +
            data.project_percent +
            data.exam_percent
        );
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    const headers = createHeaders();

    const dataToSend = {
      daily_percent: parseFloat(percents.daily),
      quiz_percent: parseFloat(percents.quiz),
      project_percent: parseFloat(percents.project),
      exam_percent: parseFloat(percents.exam),
    };

    if (total === 100) {
      try {
        const response = await fetch(`${consts.API_URL}/admin/setting`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();
        console.log("Ответ от API:", responseData);

        if (response.ok) {
          console.log("Данные успешно обновлены:", responseData);
        } else {
          console.error("Ошибка при обновлении данных:", responseData.message);
        }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };

  const handlePercentChange = (key, value) => {
    const updatedPercents = {
      ...percents,
      [key]: value,
    };

    const newTotal = Object.values(updatedPercents).reduce(
      (acc, curr) => acc + Number(curr),
      0
    );

    setPercents(updatedPercents);
    setTotal(newTotal);
  };

  if (loading) return <div>Yüklənir...</div>;

  return (
    <div className="groups">
      <div className="groups-header">
        <button className="add-button" onClick={() => setIsEdit(true)}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.9893 15.8934L6.96677 15.0979C7.11197 15.0689 7.24532 14.9975 7.35002 14.8928L12.3374 9.90543C12.6303 9.61254 12.6303 9.13766 12.3374 8.84477L9.15539 5.66279C8.86249 5.3699 8.38761 5.3699 8.09472 5.66279L3.10738 10.6502C3.00268 10.7549 2.93131 10.8882 2.90227 11.0334L2.10678 15.0109C2.00182 15.5357 2.46451 15.9984 2.9893 15.8934Z"
              fill="#101110"
            />
            <path
              d="M15.1444 3.91645L14.0837 2.85579C13.2051 1.97711 11.7804 1.97711 10.9018 2.85579L10.1628 3.59475C9.86991 3.88764 9.86991 4.36252 10.1628 4.65541L13.3448 7.83739C13.6377 8.13029 14.1125 8.13028 14.4054 7.83739L15.1444 7.09843C16.0231 6.21975 16.0231 4.79513 15.1444 3.91645Z"
              fill="#101110"
            />
          </svg>
          <div>Redaktə et</div>
        </button>
      </div>
      <div className="groups-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="left column-content">Qiymət tipi</div>
              </th>
              <th>
                <div className="right column-content">Faiz</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {[
              { label: "Günlük", key: "daily", value: percents.daily },
              { label: "Quiz", key: "quiz", value: percents.quiz },
              { label: "İmtahan", key: "exam", value: percents.exam },
              { label: "Disiplin", key: "project", value: percents.project },
            ].map((data, i) => (
              <EvaluateTableItem
                data={data}
                key={i}
                isEdit={isEdit}
                onPercentChange={handlePercentChange}
                total={total}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="groups-footer p-0">
        {isEdit && (
          <button
            className="add-button m-3"
            onClick={() => {
              if (total === 100) {
                setIsEdit(false);
              }
            }}
          >
            <div onClick={handleSubmit}>Yadda saxla</div>
          </button>
        )}
      </div>
    </div>
  );
};

export default EvaluateTable;
