export const groupstable = [
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    formation: "Qrup",
    turn: "Axşam",
    profession: "IT Business Analytics",
  },
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    turn: "Axşam",
    formation: "Qrup",
    profession: "IT Business Analytics",
  },
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    turn: "Axşam",
    formation: "Fərdi",
    profession: "IT Business Analytics",
  },
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    turn: "Axşam",
    formation: "Qrup",
    profession: "IT Business Analytics",
  },
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    turn: "Axşam",
    formation: "Fərdi",
    profession: "IT Business Analytics",
  },
  {
    time: "29.08.2024",
    group: "Q-IB030724A-EƏ",
    teacher: "Fuad Süleymanlı",
    turn: "Axşam",
    formation: "Fərdi",
    profession: "IT Business Analytics",
  },
];

export const studentTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 2",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "",
    groups: [],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
];

export const teacherTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "",
    groups: [],
  },
];

export const graduatesTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    group_name: "",
    groups: [],
  },
];

export const draftersTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
];

export const deletedTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    email: "rehman.q@coders.edu.az",
    phone_number: "+994 (50) 327 55 63",
    groups: "Q-IB030724A-EƏ",
  },
];

export const teachersActivityTable = [
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    time: "29.08.2024 13:00",
    activity: "Qrupa imtahan qiyməti yazdi",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    time: "29.08.2024 13:00",
    activity: "Qrupa imtahan qiyməti yazdi",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    time: "29.08.2024 13:00",
    activity: "Qrupa imtahan qiyməti yazdi",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    time: "29.08.2024 13:00",
    activity: "Qrupa imtahan qiyməti yazdi",
  },
  {
    image: "/img/avatar_student.png",
    name: "Rəhman Qafarov",
    time: "29.08.2024 13:00",
    activity: "Qrupa imtahan qiyməti yazdi",
  },
];

export const teachingDirectionsTable = [
  {
    time: "29.08.2024",
    direction: "IT Business Analytics",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    time: "29.08.2024",
    direction: "IT Business Analytics",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
  {
    time: "29.08.2024",
    direction: "IT Business Analytics",
    group_name: "Qruplar — 1",
    groups: ["Q-MT130923A-HT", "İ-MT130124-HT"],
  },
];

export const exercisesNavbar = [
  {
    path: "/exercises/current",
    name: "Hazırki",
  },
  {
    path: "/exercises/future",
    name: "Gələcək",
  },
  {
    path: "/exercises/past",
    name: "Keçmiş",
  },
  {
    path: "/exercises/draft",
    name: "Qaralama",
  },
];

export const gradeTable = [
  {
    name: "Fəridə Nəcəf",
    email: "/img/email.png",
    phone_number: "/img/phone.png",
    overall_average: "80.43",
    weekly_average: "80.43",
  },
  {
    name: "Fəridə Nəcəf",
    email: "/img/email.png",
    phone_number: "/img/phone.png",
    overall_average: "80.43",
    weekly_average: "80.43",
  },
  {
    name: "Fəridə Nəcəf",
    email: "/img/email.png",
    phone_number: "/img/phone.png",
    overall_average: "80.43",
    weekly_average: "80.43",
  },
];

export const exercisesCard = [
  {
    id: 1,
    time: "24.10.2024",
    name: "Tapşırığın adı",
    text: "Qısa mətn",
    directions: "option1", // Add this field
    participantType: "group", // or "student"
    group: [], // or empty if participantType is "student"
    student: [], // or a valid student ID if participantType is "student"
    files: [], // Add this if you are handling file uploads
    group_name: "Java Back-End",
    groups: ["/img/avatar_profile.png", "/img/avatar_profile.png"],
    end_time: "01.09.2024",
    start_time: "01.08.2024",
    content:
      "Next Friday should be done. Next Monday we should deliver the first iteration. Make sure, we have a good result to be delivered by the day. \n\nI made some wireframes that we would like you to follow since we are building it in Google’s Material Design (Please learn more about this and see how to improve standard material design into something beautiful). But besides that, you can just do it how you like.",
  },
  {
    id: 2,
    time: "24.10.2024",
    name: "Tapşırığın adı",
    text: "Qısa mətn",
    directions: "option2", // Add this field
    participantType: "student", // or "group"
    group: [], // or empty if participantType is "student"
    student: [], // or empty if participantType is "group"
    files: [], // Add this if you are handling file uploads
    group_name: "Java Back-End",
    groups: ["/img/avatar_profile.png", "/img/avatar_profile.png"],
    end_time: "01.09.2024",
    start_time: "01.08.2024",
    content:
      "Next Friday should be done. Next Monday we should deliver the first iteration. Make sure, we have a good result to be delivered by the day. \n\nI made some wireframes that we would like you to follow since we are building it in Google’s Material Design (Please learn more about this and see how to improve standard material design into something beautiful). But besides that, you can just do it how you like.",
  },
  {
    id: 3,
    time: "24.10.2024",
    name: "Tapşırığın adı",
    text: "Qısa mətn",
    directions: "option3", // Add this field
    participantType: "group", // or "student"
    group: [], // or empty if participantType is "student"
    student: [], // or a valid student ID if participantType is "student"
    files: [], // Add this if you are handling file uploads
    group_name: "Java Back-End",
    groups: ["/img/avatar_profile.png", "/img/avatar_profile.png"],
    end_time: "01.09.2024",
    start_time: "01.08.2024",
    content:
      "Next Friday should be done. Next Monday we should deliver the first iteration. Make sure, we have a good result to be delivered by the day. \n\nI made some wireframes that we would like you to follow since we are building it in Google’s Material Design (Please learn more about this and see how to improve standard material design into something beautiful). But besides that, you can just do it how you like.",
  },
  {
    id: 4,
    time: "24.10.2024",
    name: "Tapşırığın adı",
    text: "Qısa mətn",
    directions: "option1", // Add this field
    participantType: "student", // or "group"
    group: [], // or empty if participantType is "student"
    student: [], // or empty if participantType is "group"
    files: [], // Add this if you are handling file uploads
    group_name: "Java Back-End",
    groups: ["/img/avatar_profile.png", "/img/avatar_profile.png"],
    end_time: "01.09.2024",
    start_time: "01.08.2024",
    content: "Lorem",
  },
];

export const languageTable = [
  {
    time: "24.10.2024",
    lang: "Azərbaycan dili",
  },
  {
    time: "24.10.2024",
    lang: "English",
  },
  {
    time: "24.10.2024",
    lang: "Русский",
  },
];

export const evaluateTable = [
  {
    name: "Günlük",
    percent: "25%",
  },
  {
    name: "Quiz",
    percent: "30%",
  },
  {
    name: "İmtahan",
    percent: "25%",
  },
  {
    name: "Disiplin",
    percent: "25%",
  },
];

export const notesTable = [
  {
    image: "/img/avatar_student.png",
    name: "Fəridə Nəcəf",
    note: "Dərslərinə yaxşı hazırlaş Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero adipisci ex sunt doloremque est veniam facilis sed exercitationem sit ab.",
    date: "29.08.2024",
    type: "Günlük",
  },
  {
    image: "/img/avatar_student.png",
    name: "Fəridə Nəcəf",
    note: "Oxusa başı var, amma Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, distinctio?",
    date: "29.08.2024",
    type: "Günlük",
  },
  {
    image: "/img/avatar_student.png",
    name: "Fəridə Nəcəf",
    note: "Dərslərinə yaxşı hazırlaş Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero adipisci ex sunt doloremque est veniam facilis sed exercitationem sit ab.",
    date: "29.08.2024",
    type: "Günlük",
  },
];

export const students = [
  {
    name: "Fəridə Nəcəf",
  },
  {
    name: "Ələsgər Həşimli",
  },
  {
    name: "Arif Təmənnazadə",
  },
  {
    name: "Səriştə Ələkrərova",
  },
  {
    name: "Fəziləddin Sülhsüvər",
  },
  {
    name: "Aytac Tapdıq",
  },
  {
    name: "Səlim Babalı",
  },
];

export const deletedTableSelect = [
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
];

export const deletedTableHeading = [
  {
    name: "Foto",
    value: "IMAGE",
  },
  {
    name: "Ad və Soyad",
    value: "FULLNAME",
  },
  {
    name: "Email",
    value: "EMAIL",
  },
  {
    name: "Telefon",
    value: "PHONE",
  },
  {
    name: "Qrup",
    value: "GROUP",
  },
];

export const groupsTableSelect = [
  { name: "Tarix", value: "start_date" }, // Sort by start date
  { name: "Qrup", value: "name" }, // Sort by group name
  { name: "Müəllim", value: "teacher.full_name" }, // Sort by teacher's full name
  { name: "Tədrisin təşkili", value: "type" }, // Sort by education method
  { name: "Fənn", value: "subject.name" } // Sort by direction
];

export const groupsTableHeading = [
  {
    name: "Tarix",
    value: "time",
  },
  {
    name: "Qrup",
    value: "name",
  },
  {
    name: "Müəllim",
    value: "full_name",
  },
  {
    name: "Cədvəl",
    value: "TABLE",
  },
  {
    name: "Tədrisin təşkili",
    value: "type",
  },
  {
    name: "Fənn ",
    value: "direction",
  },
];

export const studentTableHeading = [
  {
    name: "Foto",
    value: "image",
  },
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
  {
    name: "Qrup",
    value: "group",
  },
];

export const studentTableSelect = [
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
];


export const teacherTableHeading = [
  {
    name: "Foto",
    value: "IMAGE",
  },
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
  {
    name: "Qrup",
    value: "GROUP",
  },
];

export const teacherTableSelect = [
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
];

export const graduatesTableSelect = [
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
];

export const graduatesTableHeading = [
  {
    name: "Foto",
    value: "IMAGE",
  },
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
  {
    name: "Qrup",
    value: "GROUP",
  },
];

export const draftersTableHeading = [
  {
    name: "Foto",
    value: "IMAGE",
  },
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
  {
    name: "Qrup",
    value: "GROUP",
  },
];

export const draftersTableSelect = [
  {
    name: "Ad və Soyad",
    value: "full_name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Telefon",
    value: "phone",
  },
];

export const teachersActivityTableHeading = [
  {
    name: "Foto",
    value: "IMAGE",
  },
  {
    name: "Ad və Soyad",
    value: "FULLNAME",
  },
  {
    name: "Tarix",
    value: "TIME",
  },
  {
    name: "Aktivlik",
    value: "ACTIVITY",
  },
];

export const teachingDirectionsTableHeading = [
  {
    name: "Tarix",
    value: "TIME",
  },
  {
    name: "Fənn",
    value: "DIRECTION",
  },
];

export const templatesTableHeading = [
  {
    name: "Şablon",
    value: "note",
  },
];
export const templatesTableSelecting = [
  {
    name: "Şablon",
    value: "note",
  },
];



