import { Col } from "react-bootstrap";

const TaskCard = ({ getData }) => {
  if (
    !getData ||
    !Array.isArray(getData.group) ||
    getData.group.length === 0
  ) {
    return <p>Hazırda qrupunuz yoxdur.</p>;
  }
  return (
    <>
      {getData.group.map((group) => (
        <Col key={group.id} md={6} className="mb-4">
          <div className="user__groups-item">
            <p className="user__groups-date">{group.start_date}</p>
            <h4 className="user__groups-name">{group.name}</h4>
            <p className="user__groups-code user__group-name">{group.group_name}</p>
          </div>
        </Col>
      ))}
    </>
  );
};

export default TaskCard;
