import { NavLink } from "react-router-dom";
import { exercisesNavbar } from "../../utils/data";

const ExercisesNavbar = () => {
  
  return (
    <>
      
      <nav>
        <ul className="exercises-navbar">
          {exercisesNavbar.map((item) => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default ExercisesNavbar;
