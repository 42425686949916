import "./App.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import MainPageAdmin from "./pages/MainPageAdmin";
import MySidebar from "./components/admin/MySidebar";
import Header from "./components/admin/Header";
import { ProSidebarProvider, useProSidebar } from "react-pro-sidebar";
import GroupsAdmin from "./pages/GroupsAdmin";
import Students from "./pages/Students";
import Footer from "./components/admin/Footer";
import GroupDetails from "./components/admin/GroupDetails";
import Teachers from "./pages/Teachers";
import Graduates from "./pages/Graduates";
import Deleted from "./pages/Deleted";
import Requests from "./pages/Requests";
import Profile from "./pages/Profile";
import WaitingProject from "./pages/WaitingProject";
import TeachersActivity from "./pages/TeachersActivity";
import TeachingDirections from "./pages/TeachingDirections";
import Exercises from "./components/teachers/Exercises";
import MainPageTeacher from "./pages/MainPageTeacher";
import MySidebarTeacher from "./components/teachers/MySidebarTeacher";
import Grade from "./pages/Grade";
import CurrentExercises from "./pages/CurrentExercises";
import FutureExercise from "./pages/FutureExercise";
import PastExercise from "./pages/PastExercise";
import DraftExercise from "./pages/DraftExercise";
import Settings from "./pages/Settings";
import AddGrade from "./pages/AddGrade";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import WatchingGroup from "./pages/WatchingGroup";
import { Helmet } from "react-helmet";
import { COMPANY_NAME, FAVICON_PATH } from "./consts/Consts";
import MySidebarStudent from "./components/students/MySidebarStudent";
import MainPageStudent from "./pages/MainPageStudent";
import Templates from "./pages/Templates";
import About from "./pages/About";
import AddGradeGroup from "./components/admin/AddGradeGroup";

function PrivateRoute({ element, role, allowedRoles }) {
  if (!role) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/" replace />;
  }

  return element;
}

function App() {
  const { pathname } = useLocation();
  const [isSidebar, setIsSidebar] = useState(true);
  const isLoginPage =
    pathname === "/" ||
    pathname === "/login" ||
    pathname === "/forget-password" ||
    pathname === "/reset-password";
  const { collapsed } = useProSidebar();
  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    if (role) {
      localStorage.setItem("role", role);
    } else {
      localStorage.removeItem("role");
    }
  }, [role]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pathname]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link id="favicon" rel="icon" href={FAVICON_PATH} />
        <title>{COMPANY_NAME}</title>
      </Helmet>

      <ProSidebarProvider>
        {!isLoginPage && <Header setRole={setRole} role={role} />}
        <div
          className="app-container"
          style={{ display: isLoginPage ? "block" : "flex" }}
        >
          {!isLoginPage ? (
            <>
              <div
                className={`sidebar ${collapsed ? "collapsed" : "expanded"}`}
              >
                {role === "admin" ? (
                  <MySidebar isSidebar={isSidebar} />
                ) : role === "teacher" ? (
                  <MySidebarTeacher isSidebar={isSidebar} />
                ) : role === "student" ? (
                  <MySidebarStudent isSidebar={isSidebar} />
                ) : null}
              </div>
              <div
                className={`main-content ${
                  collapsed ? "collapsed" : "expanded"
                }`}
              >
                <Routes>
                  {/* Административные маршруты */}
                  <Route
                    path="/admin"
                    element={
                      <PrivateRoute
                        element={<MainPageAdmin />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/groups-admin"
                    element={
                      <PrivateRoute
                        element={<GroupsAdmin />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/students"
                    element={
                      <PrivateRoute
                        element={<Students />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/teachers"
                    element={
                      <PrivateRoute
                        element={<Teachers />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/graduates"
                    element={
                      <PrivateRoute
                        element={<Graduates />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/deleted"
                    element={
                      <PrivateRoute
                        element={<Deleted />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/requests"
                    element={
                      <PrivateRoute
                        element={<Requests />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/waiting-projects"
                    element={
                      <PrivateRoute
                        element={<WaitingProject />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/teachers-activity"
                    element={
                      <PrivateRoute
                        element={<TeachersActivity />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/teaching-directions"
                    element={
                      <PrivateRoute
                        element={<TeachingDirections />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/templates"
                    element={
                      <PrivateRoute
                        element={<Templates />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/about-us"
                    element={
                      <PrivateRoute
                        element={<About />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/watching-group"
                    element={
                      <PrivateRoute
                        element={<WatchingGroup role={role} />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/group-details/:groupId"
                    element={
                      <PrivateRoute
                        element={<GroupDetails role={role} />}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />

                  {/* Маршруты для учителей */}
                  <Route
                    path="/teacher"
                    element={
                      <PrivateRoute
                        element={<MainPageTeacher />}
                        role={role}
                        allowedRoles={["teacher"]}
                      />
                    }
                  />
                  <Route
                    path="/grade"
                    element={
                      <PrivateRoute
                        element={<Grade role={role} />}
                        role={role}
                        allowedRoles={["teacher"]}
                      />
                    }
                  />
                  <Route
                    path="/exercises"
                    element={
                      <PrivateRoute
                        element={<Exercises />}
                        role={role}
                        allowedRoles={["teacher"]}
                      />
                    }
                  >
                    <Route index element={<Navigate to="current" replace />} />
                    <Route path="current" element={<CurrentExercises />} />
                    <Route path="future" element={<FutureExercise />} />
                    <Route path="past" element={<PastExercise />} />
                    <Route path="draft" element={<DraftExercise />} />
                  </Route>
                  <Route
                    path="/settings"
                    element={
                      <PrivateRoute
                        element={<Settings />}
                        role={role}
                        allowedRoles={["admin", "teacher"]}
                      />
                    }
                  />
                  <Route
                    path="/add-grade/:groupId"
                    element={
                      <PrivateRoute
                        element={<AddGrade role={role}/>}
                        role={role}
                        allowedRoles={["teacher"]}
                      />
                    }
                  />
                  <Route
                    path="/add-grades/:groupId"
                    element={
                      <PrivateRoute
                        element={<AddGradeGroup role={role}/>}
                        role={role}
                        allowedRoles={["admin"]}
                      />
                    }
                  />
                  <Route
                    path="/edit-profile"
                    element={
                      <PrivateRoute
                        element={<Profile />}
                        role={role}
                        allowedRoles={["admin", "teacher", "student"]}
                      />
                    }
                  />

                  {/* Student */}
                  <Route
                    path="/student"
                    element={
                      <PrivateRoute
                        element={<MainPageStudent />}
                        role={role}
                        allowedRoles={["student"]}
                      />
                    }
                  />
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login setRole={setRole} />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              {/* Любые другие публичные маршруты можно добавить здесь */}
            </Routes>
          )}
        </div>
        {!isLoginPage && <Footer />}
      </ProSidebarProvider>
    </>
  );
}

export default App;
