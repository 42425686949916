import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function EditTeacher({ show, handleClose, id }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "+994",
    birth_day: "",
    specialty: "",
    password: "",
  });

  const resetFormData = () => {
    setFormData({
      full_name: "",
      email: "",
      phone: "+994",
      birth_day: "",
      specialty: "",
      password: "",
    });
    setErrors({});
  };

  const [errors, setErrors] = useState({});

  const formatPhoneNumberCompact = (value) => {
    const cleanValue = value.replace(/\D/g, "");

    if (cleanValue.startsWith("994")) {
      return `+${cleanValue}`;
    } else {
      return `+994${cleanValue.slice(3)}`;
    }
  };

  const cleanPhoneNumber = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: "+994 ",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "group") {
      setFormData((prevData) => {
        const newGroup = checked
          ? [...prevData.groups, { group_id: value }]
          : prevData.groups.filter((item) => item.group_id !== value);
        return { ...prevData, groups: newGroup };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.full_name.trim()) {
      newErrors.full_name = "Ad və soyad daxil edin";
    } else if (formData.full_name.length > 64) {
      newErrors.full_name = "Ad və soyad 64 simvoldan çox olmamalıdır.";
    }

    if (!formData.email) {
      newErrors.email = "Email daxil edin.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Düzgün email daxil edin.";
      }
    }

    if (!formData.phone || formData.phone.length < 13) {
      newErrors.phone = "Telefon nömrəsini daxil edin.";
    }

    if (!formData.birth_day) {
      newErrors.birth_day = "Doğum tarixini daxil edin.";
    }

    if (!formData.specialty) {
      newErrors.specialty = "İxtisası daxil edin.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(".");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleGetData = async () => {
    try {
      const headers = createHeaders();
      const response = await fetch(`${consts.API_URL}/admin/teacher/${id}`, {
        headers,
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const data = await response.json();

      if (data.data && typeof data.data === "object") {
        const { full_name, email, phone, birth_day, specialty } = data.data;

        const formattedDate = birth_day ? parseDate(birth_day) : "";

        setFormData({
          full_name: full_name || "",
          email: email || "",
          password: "",
          phone: phone || "+994",
          birth_day: formattedDate,
          specialty: specialty || "",
        });
      } else {
        console.error("Ожидался объект, но получен:", data.data);
      }
    } catch (error) {
      handleUnauthorizedError(error, navigate);
    }
  };

  useEffect(() => {
    if (show && id) {
      handleGetData();
    }
  }, [show, id]);

  const handleSubmit = async () => {
    if (validateForm()) {
      const headers = createHeaders();
      const cleanedPhoneNumber = cleanPhoneNumber(formData.phone);
      const formattedPhoneCompact =
        formatPhoneNumberCompact(cleanedPhoneNumber);

      const dataToSend = {
        full_name: formData.full_name,
        email: formData.email,
        phone: formattedPhoneCompact,
        birth_day: formData.birth_day,
        specialty: formData.specialty,
      };

      if (formData.password.trim()) {
        dataToSend.password = formData.password;
      }

      console.log("Отправка данных:", dataToSend);

      try {
        const response = await fetch(`${consts.API_URL}/admin/teacher/${id}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();
        console.log("Ответ от API:", responseData);

        if (response.ok) {
          console.log("Учитель успешно обновлен:", responseData);
          handleClose();
          window.location.reload();
        } else {
          if (
            responseData.errors &&
            responseData.errors.email &&
            responseData.errors.email.includes(
              "The email has already been taken."
            )
          ) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: "Bu email artıq istifadə olunur.",
            }));
          } else {
            console.error("Error adding Teacher:", responseData.message);
          }
        }
      } catch (error) {
        console.error("Ошибка запроса:", error);
        handleUnauthorizedError(error, navigate);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Ad və Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  placeholder="Ad və Soyad daxil edin"
                  isInvalid={!!errors.full_name}
                  className={errors.full_name ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email daxil edin"
                  isInvalid={!!errors.email}
                  className={errors.email ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Şifrə</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Şifrəni daxil edin"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="+994 (__) ___ __ __"
                  isInvalid={!!errors.phone}
                  className={errors.phone ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formDate" className="select-form">
                <Form.Label>Doğum tarixi</Form.Label>
                <Form.Control
                  type="date"
                  name="birth_day"
                  value={formData.birth_day}
                  onChange={handleChange}
                  isInvalid={!!errors.birth_day}
                  className={`pr-5 ${errors.birth_day ? "input-error" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birth_day}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>İxtisas</Form.Label>
                <Form.Control
                  type="text"
                  name="specialty"
                  value={formData.specialty}
                  onChange={handleChange}
                  placeholder="İxtisası daxil edin"
                  isInvalid={!!errors.specialty}
                  className={errors.specialty ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.specialty}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn btn-green" onClick={handleSubmit}>
          Düzəlişləri yadda saxla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTeacher;
