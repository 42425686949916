import React, { useState, useEffect, useCallback } from "react";
import AddNoteModal from "../teachers/AddNoteModal";

function AddGradeTableItem({ data, onUpdate, role, allData }) {
  const [show, setShow] = useState(false);
  const [isPlusChecked, setIsPlusChecked] = useState(null);
  const [isQChecked, setIsQChecked] = useState(null);
  const [grade, setGrade] = useState("");
  const [note, setNote] = useState(null);

  const studentData = allData.students?.find((student) => student.id === data.id);
  const lastGrade = studentData?.grades?.length > 0 
                    ? studentData.grades[studentData.grades.length - 1] 
                    : null;

  useEffect(() => {
    if (lastGrade) {
      setGrade(lastGrade.grade || ""); // Set initial grade from the latest grade, if available
      setNote(lastGrade.note || ""); // Set initial note, if available
    }
  }, [lastGrade]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateStudent = useCallback(() => {
    const updatedStudent = {
      student_id: data.id,
      attendance: isPlusChecked ? 1 : isQChecked ? 0 : null,
      grade: grade === "" ? null : grade,
      note: note === "" ? null : note,
    };

    // Yeni dəyərləri yalnız `data`-nın müvafiq sahələri dəyişsə, göndərin
    if (
      updatedStudent.attendance !== data.attendance ||
      updatedStudent.grade !== data.grade ||
      updatedStudent.note !== data.note
    ) {
      onUpdate(updatedStudent); // Bu funksiyanı çağırın
    }
}, [isPlusChecked, isQChecked, grade, note, data, onUpdate]);


  useEffect(() => {
    updateStudent();
  }, [updateStudent]);

  const handlePlusClick = () => {
    setIsPlusChecked((prev) => !prev);
    setIsQChecked(false);
  };

  const handleQClick = () => {
    setIsQChecked((prev) => !prev);
    setIsPlusChecked(false);
    setGrade(null);
  };

  const handleGradeChange = (e) => {
    const newGrade = e.target.value === "" ? null : Number(e.target.value);
    setGrade(newGrade);
    
    if (newGrade) {
        setIsPlusChecked(true);
        setIsQChecked(false);
    } else {
        setIsPlusChecked(null);
        setIsQChecked(null);
    }
};
  const handleNoteUpdate = (newNote) => {
    setNote(newNote);
  };

  return (
    <>
      <AddNoteModal
        show={show}
        handleClose={handleClose}
        note={note}
        role={role}
        setNote={handleNoteUpdate}
      />
      <tr>
        <td style={{ width: "70%" }}>
          <div
            className="column-content"
            style={{
              paddingLeft: "20px",
              margin: "0 20px",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            {data.full_name}
          </div>
        </td>
        <td>
          <div className="column-content bg-white grade">
          <input
    type="number"
    className="grade-input evaluate-input"
    value={grade === null ? "" : grade} // Dəyəri boş olmaması üçün
    onChange={handleGradeChange}
    disabled={isQChecked}
/>


          </div>
        </td>
        <td>
          <div
            className="column-content bg-white grade plus-btn"
            onClick={handlePlusClick}
            style={{
              cursor: "pointer",
              color: isPlusChecked ? "green" : "black",
            }}
          >
            <p className={`plus-btn ${isPlusChecked ? "checked" : ""}`}>+</p>
          </div>
        </td>
        <td>
          <div
            className="column-content bg-white grade q-btn"
            onClick={handleQClick}
            style={{
              cursor: "pointer",
              color: isQChecked ? "blue" : "black",
            }}
          >
            <p className={`q-btn ${isQChecked ? "checked" : ""}`}>Q</p>
          </div>
        </td>
        <td>
          <div className="right column-content bg-white">
            <button className="edit-note" type="button" onClick={handleShow}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 6.5H6.44444C6.06135 6.5 5.69395 6.65218 5.42307 6.92307C5.15218 7.19395 5 7.56135 5 7.94444V18.0556C5 18.4386 5.15218 18.806 5.42307 19.0769C5.69395 19.3478 6.06135 19.5 6.44444 19.5H16.5556C16.9386 19.5 17.306 19.3478 17.5769 19.0769C17.8478 18.806 18 18.4386 18 18.0556V13"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.4356 4.98399C17.7173 4.6741 18.0994 4.5 18.4978 4.5C18.8962 4.5 19.2783 4.6741 19.56 4.98399C19.8417 5.29388 20 5.71418 20 6.15243C20 6.59068 19.8417 7.01099 19.56 7.32088L12.8326 14.721L10 15.5L10.7081 12.3841L17.4356 4.98399Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default AddGradeTableItem;
