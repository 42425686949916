import { Outlet } from "react-router-dom";
import ExercisesNavbar from "./ExercisesNavbar";
import AddExerciseModal from "./AddExerciseModal";
import { useState } from "react";

const Exercises = () => {
  const [modalShowAdd, setModalShowAdd] = useState(false);
  const handleModalShowAdd = () => setModalShowAdd(true);
  const handleModalCloseAdd = () => setModalShowAdd(false);

  return (
    <>
      <AddExerciseModal show={modalShowAdd} handleClose={handleModalCloseAdd} />
      <div className="exercises">
        <div>
          <h3 className="title mb-3">Tapşırıqlar</h3>
          <ExercisesNavbar />
        </div>

        <div className="buttons">
          <button
            type="button"
            className="primary-button"
            onClick={() => setModalShowAdd(true)}
          >
            Yeni tapşırıq yarat
          </button>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Exercises;
