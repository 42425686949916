import React from "react";
import UserProfile from "../UserProfile";

function TopStudents({ data }) {
  if (!data) {
    return null;
  }
  const student = data.student || {};
  const formattedAvgTotal = data.avg_total ? parseFloat(data.avg_total).toFixed(2) : '0.00';

  return (
    <div className="top__item d-flex align-items-center justify-content-between">
      <div className="top__body d-flex align-items-center">
        <div className="top__icon">
          {formattedAvgTotal > 90 ? (
                  <svg
                  width="16"
                  height="26"
                  viewBox="0 0 16 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_483_2752)">
                    <path
                      d="M11.469 1.42993V11.6879H4.57693V1.43734L8 2.84268L11.469 1.42993Z"
                      fill="#EA9600"
                    />
                    <path
                      d="M2.2944 0.5L4.57694 1.43739V11.688H2.2944V0.5Z"
                      fill="#F12222"
                    />
                    <path
                      d="M13.7531 0.5V11.688H11.4691V1.42998L13.7531 0.5Z"
                      fill="#F12222"
                    />
                    <path
                      d="M7.12495 7.82883L0.875046 11.4318C0.333704 11.7442 0 12.3203 0 12.9452V20.1511C0 20.7761 0.333704 21.3521 0.875046 21.6646L7.12495 25.2675C7.6663 25.58 8.3337 25.58 8.87505 25.2675L15.125 21.6646C15.6663 21.3521 16 20.7761 16 20.1511V12.9452C16 12.3203 15.6663 11.7442 15.125 11.4318L8.87505 7.82883C8.3337 7.51637 7.6663 7.51637 7.12495 7.82883Z"
                      fill="#BE6F15"
                    />
                    <path
                      d="M7.12495 7.24387L0.875046 10.8468C0.333704 11.1593 0 11.7353 0 12.3603V19.5662C0 20.1911 0.333704 20.7671 0.875046 21.0796L7.12495 24.6826C7.6663 24.995 8.3337 24.995 8.87505 24.6826L15.125 21.0796C15.6663 20.7671 16 20.1911 16 19.5662V12.3603C16 11.7353 15.6663 11.1593 15.125 10.8468L8.87505 7.24387C8.3337 6.93141 7.6663 6.93141 7.12495 7.24387Z"
                      fill="#FABE3F"
                    />
                    <path
                      d="M2.2247 18.6096V13.317C2.2247 12.8949 2.45014 12.504 2.81795 12.2922L7.40824 9.64588C7.77457 9.43412 8.22693 9.43412 8.59326 9.64588L13.1835 12.2922C13.5499 12.504 13.7768 12.8949 13.7768 13.317V18.6096C13.7768 19.0316 13.5514 19.4226 13.1835 19.6343L8.59326 22.2806C8.22693 22.4924 7.77457 22.4924 7.40824 22.2806L2.81795 19.6343C2.45162 19.4226 2.2247 19.0316 2.2247 18.6096Z"
                      fill="#C17700"
                    />
                    <path
                      d="M7.99999 11.8345L9.30367 14.1787L11.9392 14.6911L10.109 16.6532L10.4338 19.3143L7.99999 18.183L5.56618 19.3143L5.89247 16.6532L4.06229 14.6911L6.69781 14.1787L7.99999 11.8345Z"
                      fill="#BE6F15"
                    />
                    <path
                      d="M7.99999 11.4392L9.30367 13.7834L11.9392 14.2973L10.109 16.258L10.4338 18.9191L7.99999 17.7877L5.56618 18.9191L5.89247 16.258L4.06229 14.2973L6.69781 13.7834L7.99999 11.4392Z"
                      fill="#FABE3F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_483_2752">
                      <rect
                        width="16"
                        height="25"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
          ) : formattedAvgTotal > 80 ? (
            <svg
            width="16"
            height="26"
            viewBox="0 0 16 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_483_2752)">
              <path
                d="M11.469 1.42993V11.6879H4.57693V1.43734L8 2.84268L11.469 1.42993Z"
                fill="#747F87"
              />
              <path
                d="M2.2944 0.5L4.57694 1.43739V11.688H2.2944V0.5Z"
                fill="#1E2936"
              />
              <path
                d="M13.7531 0.5V11.688H11.4691V1.42998L13.7531 0.5Z"
                fill="#1E2936"
              />
              <path
                d="M7.12495 7.82883L0.875046 11.4318C0.333704 11.7442 0 12.3203 0 12.9452V20.1511C0 20.7761 0.333704 21.3521 0.875046 21.6646L7.12495 25.2675C7.6663 25.58 8.3337 25.58 8.87505 25.2675L15.125 21.6646C15.6663 21.3521 16 20.7761 16 20.1511V12.9452C16 12.3203 15.6663 11.7442 15.125 11.4318L8.87505 7.82883C8.3337 7.51637 7.6663 7.51637 7.12495 7.82883Z"
                fill="#212A35"
              />
              <path
                d="M7.12495 7.24387L0.875046 10.8468C0.333704 11.1593 0 11.7353 0 12.3603V19.5662C0 20.1911 0.333704 20.7671 0.875046 21.0796L7.12495 24.6826C7.6663 24.995 8.3337 24.995 8.87505 24.6826L15.125 21.0796C15.6663 20.7671 16 20.1911 16 19.5662V12.3603C16 11.7353 15.6663 11.1593 15.125 10.8468L8.87505 7.24387C8.3337 6.93141 7.6663 6.93141 7.12495 7.24387Z"
                fill="#B8B9C0"
              />
              <path
                d="M2.2247 18.6096V13.317C2.2247 12.8949 2.45014 12.504 2.81795 12.2922L7.40824 9.64588C7.77457 9.43412 8.22693 9.43412 8.59326 9.64588L13.1835 12.2922C13.5499 12.504 13.7768 12.8949 13.7768 13.317V18.6096C13.7768 19.0316 13.5514 19.4226 13.1835 19.6343L8.59326 22.2806C8.22693 22.4924 7.77457 22.4924 7.40824 22.2806L2.81795 19.6343C2.45162 19.4226 2.2247 19.0316 2.2247 18.6096Z"
                fill="#656875"
              />
              <path
                d="M7.99999 11.8345L9.30367 14.1787L11.9392 14.6911L10.109 16.6532L10.4338 19.3143L7.99999 18.183L5.56618 19.3143L5.89247 16.6532L4.06229 14.6911L6.69781 14.1787L7.99999 11.8345Z"
                fill="#212A35"
              />
              <path
                d="M7.99999 11.4392L9.30367 13.7834L11.9392 14.2973L10.109 16.258L10.4338 18.9191L7.99999 17.7877L5.56618 18.9191L5.89247 16.258L4.06229 14.2973L6.69781 13.7834L7.99999 11.4392Z"
                fill="#B8B9C0"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_2752">
                <rect
                  width="16"
                  height="25"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          ) : (
            <svg
            width="16"
            height="26"
            viewBox="0 0 16 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_483_2752)">
              <path
                d="M11.469 1.42993V11.6879H4.57693V1.43734L8 2.84268L11.469 1.42993Z"
                fill="#CD7F32"
              />
              <path
                d="M2.2944 0.5L4.57694 1.43739V11.688H2.2944V0.5Z"
                fill="#A97142"
              />
              <path
                d="M13.7531 0.5V11.688H11.4691V1.42998L13.7531 0.5Z"
                fill="#A97142"
              />
              <path
                d="M7.12495 7.82883L0.875046 11.4318C0.333704 11.7442 0 12.3203 0 12.9452V20.1511C0 20.7761 0.333704 21.3521 0.875046 21.6646L7.12495 25.2675C7.6663 25.58 8.3337 25.58 8.87505 25.2675L15.125 21.6646C15.6663 21.3521 16 20.7761 16 20.1511V12.9452C16 12.3203 15.6663 11.7442 15.125 11.4318L8.87505 7.82883C8.3337 7.51637 7.6663 7.51637 7.12495 7.82883Z"
                fill="#CD7F32"
              />
              <path
                d="M7.12495 7.24387L0.875046 10.8468C0.333704 11.1593 0 11.7353 0 12.3603V19.5662C0 20.1911 0.333704 20.7671 0.875046 21.0796L7.12495 24.6826C7.6663 24.995 8.3337 24.995 8.87505 24.6826L15.125 21.0796C15.6663 20.7671 16 20.1911 16 19.5662V12.3603C16 11.7353 15.6663 11.1593 15.125 10.8468L8.87505 7.24387C8.3337 6.93141 7.6663 6.93141 7.12495 7.24387Z"
                fill="#A97142"
              />
              <path
                d="M2.2247 18.6096V13.317C2.2247 12.8949 2.45014 12.504 2.81795 12.2922L7.40824 9.64588C7.77457 9.43412 8.22693 9.43412 8.59326 9.64588L13.1835 12.2922C13.5499 12.504 13.7768 12.8949 13.7768 13.317V18.6096C13.7768 19.0316 13.5514 19.4226 13.1835 19.6343L8.59326 22.2806C8.22693 22.4924 7.77457 22.4924 7.40824 22.2806L2.81795 19.6343C2.45162 19.4226 2.2247 19.0316 2.2247 18.6096Z"
                fill="#CD7F32"
              />
              <path
                d="M7.99999 11.8345L9.30367 14.1787L11.9392 14.6911L10.109 16.6532L10.4338 19.3143L7.99999 18.183L5.56618 19.3143L5.89247 16.6532L4.06229 14.6911L6.69781 14.1787L7.99999 11.8345Z"
                fill="#CD7F32"
              />
              <path
                d="M7.99999 11.4392L9.30367 13.7834L11.9392 14.2973L10.109 16.258L10.4338 18.9191L7.99999 17.7877L5.56618 18.9191L5.89247 16.258L4.06229 14.2973L6.69781 13.7834L7.99999 11.4392Z"
                fill="#A97142"
              />
            </g>
            <defs>
              <clipPath id="clip0_483_2752">
                <rect
                  width="16"
                  height="25"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          
          )}


        </div>
        <div className="top__img">
          {student.image ? (
            <img
              src={student.image}
              alt=""
              className="top-students-image"
            />
          ) : (
            <UserProfile imageSize={24} containerSize={38} />
          )}
        </div>
        <div className="top__member">
          <h5 className="top__name">{student.full_name || 'Unknown Student'}</h5>
          <p className="top__group">{data.subject?.name || 'No Subject'}</p>
        </div>
      </div>

      <div className="top__score">{formattedAvgTotal}</div>
    </div>
  );
}

export default TopStudents;
