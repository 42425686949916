import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as consts from "../../consts/Consts";
import {
  fetchSubjects,
  fetchTeachers,
  createHeaders,
} from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

const weekDaysMapping = {
  "B.e.": "monday",
  "Ç.a.": "tuesday",
  "Ç.": "wednesday",
  "C.a.": "thursday",
  "C.": "friday",
  "Ş.": "saturday",
  "B.": "sunday",
};

const EditGroup = ({ show, handleClose, id, data }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    subject_id: "",
    teacher_id: "",
    name: "",
    type: "",
    start_date: "",
    schedules: [],
    week_day: [],
    time: {},
  });

  const [errors, setErrors] = useState({});

  const resetFormData = () => {
    setFormData({
      subject_id: "",
      teacher_id: "",
      name: "",
      type: "",
      start_date: "",
      schedules: [],
      week_day: [],
      time: {},
    });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleTimeChange = (e, day) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: {
        ...prevFormData.time,
        [day]: e.target.value,
      },
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Qrupun adını daxil edin";
    } else if (formData.name.length > 64) {
      newErrors.name = "Qrupun adı 64 simvoldan çox olmamalıdır.";
    }

    if (!formData.type) {
      newErrors.type = "Tədrisin təşkilini seçin.";
    }

    if (!formData.subject_id) {
      newErrors.subject_id = "Tədrisin istiqamətini seçin";
    }

    if (!formData.start_date) {
      newErrors.start_date = "Başlanma tarixini seçin.";
    }

    if (!formData.teacher_id) {
      newErrors.teacher_id = "Müəllimi seçin.";
    }

    if (formData.week_day.length === 0) {
      newErrors.week_day = "Ən azı bir gün seçilməlidir.";
    }

    formData.week_day.forEach((day) => {
      const time = formData.time[day];
      if (!time) {
        newErrors[day] = `${day} günü üçün vaxt daxil edin.`;
      } else if (!validateTime(time)) {
        newErrors[day] = `${day} günü üçün vaxt düzgün formatda deyil.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };

  const validateTime = (value) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const weekDayEnglish = weekDaysMapping[value];

    setFormData((prevData) => {
      const newWeekDays = checked
        ? [...prevData.week_day, value]
        : prevData.week_day.filter((day) => day !== value);

      const newSchedules = newWeekDays.map((day) => ({
        week_day: weekDaysMapping[day],
        time: prevData.time[day] || "",
      }));

      return {
        ...prevData,
        week_day: newWeekDays,
        schedules: newSchedules,
      };
    });
  };

  const handleTimeInputChange = (e, label) => {
    const { value } = e.target;
    const weekDayEnglish = weekDaysMapping[label];

    setFormData((prevData) => {
      const newSchedules = prevData.schedules.map((schedule) =>
        schedule.week_day === weekDayEnglish
          ? { ...schedule, time: value }
          : schedule
      );

      return {
        ...prevData,
        time: {
          ...prevData.time,
          [label]: value,
        },
        schedules: newSchedules,
      };
    });
  };

  const handleTimeCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: {
        ...prevFormData.time,
        [name]: checked ? "10:00" : "",
      },
    }));
  };

  const [getData, setGetData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSubjects();
        setGetData(data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTeachers(null, 100);
        setTeachers(data.items);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split(".");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleGetData = async () => {
    try {
      const headers = createHeaders();
      const response = await fetch(`${consts.API_URL}/admin/group/${data.id}`, {
        headers,
      });

      if (!response.ok) {
        throw new Error("Ошибка при получении данных");
      }

      const result = await response.json();
      if (result.data && typeof result.data === "object") {
        const { name, teacher, type, subject, start_date, schedules } =
          result.data;

        const formattedDate = start_date ? parseDate(start_date) : "";

        setFormData({
          name: name || "",
          teacher_id: teacher?.id || "",
          subject_id: subject?.id || "",
          type: type || "",
          start_date: formattedDate,
          schedules: Array.isArray(schedules) ? schedules : [],
          week_day: schedules.map((schedule) =>
            Object.keys(weekDaysMapping).find(
              (key) => weekDaysMapping[key] === schedule.week_day
            )
          ),
          time: schedules.reduce(
            (acc, schedule) => ({
              ...acc,
              [Object.keys(weekDaysMapping).find(
                (key) => weekDaysMapping[key] === schedule.week_day
              )]: schedule.time,
            }),
            {}
          ),
        });
      } else {
        console.error("Ожидался объект, но получен:", result.data);
      }
    } catch (error) {
      handleUnauthorizedError(error, navigate);
    }
  };

  useEffect(() => {
    if (show && data?.id) {
      handleGetData();
    }
  }, [show, data?.id]);

  const handleSubmit = async () => {
    if (validateForm()) {
      const schedules = formData.week_day.map((weekDay) => ({
        week_day: weekDaysMapping[weekDay],
        time: formData.time[weekDay] || "",
      }));
      const headers = createHeaders();

      try {
        await fetch(`${consts.API_URL}/admin/group/${id}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            subject_id: formData.subject_id,
            teacher_id: formData.teacher_id,
            name: formData.name,
            type: formData.type,
            start_date: formData.start_date,
            schedules,
          }),
        });
        handleCloseModal();
        window.location.reload()
      } catch (error) {
        console.error("Ошибка при обновлении группы:", error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <div className="modal-title mb-3">
          <span>Yeni qrup məlumatları</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formName">
                <Form.Label>Qrupun adı</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Qrupun adını daxil edin"
                  isInvalid={!!errors.name}
                  className={errors.name ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formSelect" className="select-form">
                <Form.Label>Fənn</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    as="select"
                    name="subject_id"
                    value={formData.subject_id}
                    onChange={handleChange}
                    isInvalid={!!errors.subject_id}
                    className={`pr-5 ${errors.subject_id ? "input-error" : ""}`}
                  >
                    <option value="">İstiqaməti seçin</option>
                    {getData.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.translations[0].name}
                      </option>
                    ))}
                  </Form.Control>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#6F767E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <Form.Control.Feedback type="invalid">
                  {errors.subject_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formType" className="select-form">
                <Form.Label>Tədrisin təşkili</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    as="select"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    isInvalid={!!errors.type}
                    className={`pr-5 ${errors.type ? "input-error" : ""}`}
                  >
                    <option value="">Siyahıdan seçin</option>
                    <option value="group">Qrup</option>
                    <option value="individual">Fərdi</option>
                  </Form.Control>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#6F767E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formStartDate" className="select-form">
                <Form.Label>Başlanma tarixi</Form.Label>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleChange}
                  isInvalid={!!errors.start_date}
                  className={`pr-5 ${errors.start_date ? "input-error" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.start_date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formTeacher" className="select-form">
                <Form.Label>Müəllimi seçin</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    as="select"
                    name="teacher_id"
                    value={formData.teacher_id}
                    onChange={handleChange}
                    isInvalid={!!errors.teacher_id}
                    className={`pr-5 ${errors.teacher_id ? "input-error" : ""}`}
                  >
                    <option value="">Seçin...</option>
                    {teachers &&
                      teachers.map((teacher) => (
                        <option key={teacher.id} value={teacher.id}>
                          {teacher.full_name}
                        </option>
                      ))}
                  </Form.Control>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#6F767E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.teacher_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="schedule mb-4">
            <Col>
              <Form.Group
                controlId="formCheckbox"
                className="custom-checkbox-wrapper"
              >
                <Form.Label>Həftənin günləri</Form.Label>
                <div className="d-flex flex-wrap custom-checkbox-row">
                  {["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."].map(
                    (label, index) => (
                      <div className="custom-checkbox" key={index}>
                        <input
                          type="checkbox"
                          id={`checkbox-${index}`}
                          value={label}
                          checked={formData.week_day.includes(label)}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label
                          className="checkbox-label"
                          htmlFor={`checkbox-${index}`}
                        >
                          {label}
                        </label>
                      </div>
                    )
                  )}
                </div>
                {/* {errors.week_day && (
                  <Form.Control.Feedback type="invalid">
                    {errors.week_day}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row className="time-checkboxes">
            <Col>
              <Form.Group
                controlId="formTimeCheckbox"
                className="custom-checkbox-wrapper"
              >
                <Form.Label>Zaman</Form.Label>
                <div className="d-flex flex-wrap custom-checkbox-row">
                  {["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."].map(
                    (label, index) => (
                      <div
                        className={`custom-checkbox ${
                          formData.week_day.includes(label) ? "active" : ""
                        }`}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          id={`time-checkbox-${index}`}
                          checked={!!formData.time[label]}
                          onChange={() => handleTimeCheckboxChange(label)}
                          disabled={!formData.week_day.includes(label)}
                        />
                        <label
                          className="checkbox-label no-border-label"
                          htmlFor={`time-checkbox-${index}`}
                        >
                          <InputMask
                            mask="99:99"
                            value={
                              formData.week_day.includes(label)
                                ? formData.time[label] || ""
                                : ""
                            }
                            onChange={(e) => handleTimeInputChange(e, label)}
                            onBlur={(e) => handleTimeInputChange(e, label)}
                            disabled={!formData.week_day.includes(label)}
                            placeholder="00:00"
                            className={`time-input ${
                              errors.time?.[label] ? "input-error" : ""
                            }`}
                          />
                        </label>
                      </div>
                    )
                  )}
                </div>
                {errors.time && (
                  <div className="invalid-feedback d-block">{errors.time}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3 error-form">
              {errors.week_day && (
                <div className="text-danger">{errors.week_day}</div>
              )}
              {Object.keys(errors)
                .filter(
                  (key) => key !== "week_day" && formData.week_day.includes(key)
                )
                .map((key) => (
                  <div key={key} className="text-danger">
                    {errors[key]}
                  </div>
                ))}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn btn-green" onClick={handleSubmit}>
          Qrupu əlavə et
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGroup;
