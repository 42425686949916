import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { deletedTableHeading, deletedTableSelect } from "../../utils/data";
import DeletedTableItem from "./DeletedTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import AlertModal from "./AlertModal";
import {
  fetchDeletedStudents,
  fetchDeletedTeachers,
} from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function DeletedTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [openSelectThree, setOpenSelectThree] = useState(false);
  const [student, setStudent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deletedStudents, setDeletedStudents] = useState([]);
  const [deletedTeachers, setDeletedTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("Tələbə");
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('full_name');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDeletedStudents(navigate, perPage, currentPage, search, sortField, sortOrder);
        setDeletedStudents(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [perPage, currentPage, navigate, search, sortField, sortOrder]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDeletedTeachers(navigate, perPage, currentPage, search, sortField, sortOrder);
        setDeletedTeachers(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [perPage, currentPage, search, sortField, sortOrder, navigate]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to the first page when search changes
  };
  const handleSortChange = (value) => {
    const [field, order] = value.split(' '); // e.g., "full_name asc" or "email desc"
    setSortField(field);
    setSortOrder(order);
  };

  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <Form.Select
              className={`selection ${openSelectThree ? "active" : ""}`}
              onClick={() => setOpenSelectThree((prev) => !prev)}
              onChange={handleTypeChange} // Обрабатываем изменение типа
            >
              <option value="Tələbə">Tələbə</option>
              <option value="Müəllim">Müəllim</option>
            </Form.Select>

            <FilterSelect data={deletedTableSelect} onSelect={handleSortChange} />

            <div className="search-box">
              <input
                type="text"
                placeholder="Axtar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {deletedTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                      {sortField === item.value && (
                        <span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
                      )}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {selectedType === "Tələbə" &&
                deletedStudents &&
                deletedStudents.map((deleted, i) => (
                  <DeletedTableItem
                    data={deleted}
                    key={i}
                    tableType="student"
                  />
                ))}

              {selectedType === "Müəllim" &&
                deletedTeachers &&
                deletedTeachers.map((deleted, i) => (
                  <DeletedTableItem
                    data={deleted}
                    key={i}
                    tableType="teacher"
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />
          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default DeletedTable;
