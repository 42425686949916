import * as consts from "../consts/Consts";
import apiToken from "./tokenUtils";
import {
  generateRandomNonce,
  generateSignature,
  getCurrentDatetime,
  getCurrentEpochDivided,
  handleUnauthorizedError,
} from "./authUtils";
import { useNavigate } from "react-router-dom";

const apiUrls = {
  groups: `${consts.API_URL}/admin/group`,
  subjects: `${consts.API_URL}/admin/subject`,
  teachers: `${consts.API_URL}/admin/teacher`,
  students: `${consts.API_URL}/admin/student/get/status/student`,
  graduates: `${consts.API_URL}/admin/student/get/status/graduated`,
  pendings: `${consts.API_URL}/admin/student/get/status/pending`,
  aboutMe: `${consts.API_URL}/auth/me`,
  contact: `${consts.API_URL}/contact`,
  subjectPost: `${consts.API_URL}/admin/subject`,
  deletedStudents: `${consts.API_URL}/admin/student/get/trashed`,
  deletedTeachers: `${consts.API_URL}/admin/teacher/get/trashed`,
  settingsGrade: `${consts.API_URL}/admin/setting`,
  settingsGradeUpdate: `${consts.API_URL}/admin/setting`,
  teacherGroups: `${consts.API_URL}/teacher/group`,
  appeals: `${consts.API_URL}/admin/appeal`,
  currentTask: `${consts.API_URL}/teacher/task?filter=current&is_draft=0`,
  futureTask: `${consts.API_URL}/teacher/task?filter=future&is_draft=0`,
  pastTask: `${consts.API_URL}/teacher/task?filter=past&is_draft=0`,
  draftTask: `${consts.API_URL}/teacher/task?is_draft=1`,
  studentList: `${consts.API_URL}/teacher/student`,
  appealCount: `${consts.API_URL}/admin/appeal/get/count`,
  teachersSubject: `${consts.API_URL}/subject`,
  templates: `${consts.API_URL}/admin/note-template`,
  aboutUs: `${consts.API_URL}/admin/about`,
};

const createHeaders = () => {
  const datetime = getCurrentDatetime();
  const nonce = generateRandomNonce(8);
  const salt = "NSP-Coders_(!#12!)_";
  const epochDivided = getCurrentEpochDivided();
  const signature = generateSignature(datetime, nonce, salt, epochDivided);

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiToken()}`,
    Datetime: datetime,
    Nonce: nonce,
    Signature: signature,
  };
};

const fetchData = async (url, navigate) => {
  try {
    const headers = createHeaders();
    const response = await fetch(url, { headers });

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorizedError(response, navigate);
      } else {
        throw new Error(`Ошибка при получении данных: ${response.statusText}`);
      }
    }

    const data = await response.json();
    return data.data.filter(
      (item) => item.is_blocked === undefined || item.is_blocked === 0
    );
  } catch (error) {
    console.error("Ошибка:", error);
    throw error;
  }
};

const fetchDataWithoutFilter = async (url, navigate) => {
  try {
    const headers = createHeaders();
    const response = await fetch(url, { headers });

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorizedError(response, navigate);
      } else {
        throw new Error(`Ошибка при получении данных: ${response.statusText}`);
      }
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Ошибка:", error);
    throw error;
  }
};

const fetchDataWithPost = async (url, navigate, bodyData) => {
  try {
    const headers = createHeaders();
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorizedError(response, navigate);
      } else {
        throw new Error(`Ошибка при отправке данных: ${response.statusText}`);
      }
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Ошибка:", error);
    throw error;
  }
};

const fetchDataPagination = async (
  url,
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = "",
  type = ""
) => {
  try {
    const headers = createHeaders();
    let paginatedUrl = `${url}?pagination=${perPage}&page=${page}`;

    if (search) {
      paginatedUrl += `&${search}`;
    }

    if (type) {
      paginatedUrl += `&type=${encodeURIComponent(type)}`; // Add type filter to URL
    }

    if (sortField) {
      const validSortOrder =
        sortOrder === "asc" || sortOrder === "desc" ? sortOrder : "asc";
      paginatedUrl += `&sort[${encodeURIComponent(
        sortField
      )}]=${validSortOrder}`;
    }

    const response = await fetch(paginatedUrl, { headers });

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorizedError(response, navigate);
      } else {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
    }

    const data = await response.json();

    return {
      items: data.data,
      totalPages: data.pagination.total_pages,
      perPage: data.pagination.per_page,
      currentPage: data.pagination.current_page,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createSubjectPost = async (navigate, groupData) => {
  return await fetchDataWithPost(apiUrls.subjectPost, navigate, groupData);
};

export const fetchSubjects = async (navigate) => {
  return await fetchData(apiUrls.subjects, navigate);
};

export const fetchTeachersSubjects = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.teachersSubject, navigate);
};

export const fetchTeacherGroups = async (navigate) => {
  return await fetchData(apiUrls.teacherGroups, navigate);
};

// export const fetchTeacherGroups = async (
//   navigate,
//   perPage = 5,
//   page = 1,
//   sortField = "",
//   sortOrder = ""
// ) => {
//   return await fetchDataPagination(
//     apiUrls.pendings,
//     navigate,
//     perPage,
//     page,
//     sortField,
//     sortOrder
//   );
// };

export const fetchTeachers = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.teachers,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchStudentList = async (navigate, perPage = 5, page = 1) => {
  return await fetchDataPagination(
    apiUrls.studentList,
    navigate,
    perPage,
    page
  );
};

export const fetchPendings = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.pendings,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};
export const fetchTemplates = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.templates,
    navigate,
    perPage,
    page,
    `note=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchAppeals = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = "",
  type = ""
) => {
  return await fetchDataPagination(
    apiUrls.appeals,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder,
    type
  );
};

export const fetchGroups = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.groups,
    navigate,
    perPage,
    page,
    `name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchStudents = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.students,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchGraduates = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.graduates,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchDeletedStudents = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.deletedStudents,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchDeletedTeachers = async (
  navigate,
  perPage = 5,
  page = 1,
  search = "",
  sortField = "",
  sortOrder = ""
) => {
  return await fetchDataPagination(
    apiUrls.deletedTeachers,
    navigate,
    perPage,
    page,
    `full_name=${encodeURIComponent(search)}`,
    sortField,
    sortOrder
  );
};

export const fetchAboutMe = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.aboutMe, navigate);
};

export const fetchContact = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.contact, navigate);
};

export const fetchAppealCount = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.appealCount, navigate);
};

export const fetchCurrentTask = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.currentTask, navigate);
};

export const fetchFutureTask = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.futureTask, navigate);
};

export const fetchPastTask = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.pastTask, navigate);
};

export const fetchDraftTask = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.draftTask, navigate);
};

export const fetchSettingsGrade = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.settingsGrade, navigate);
};

export const fetchAboutUs = async (navigate) => {
  return await fetchDataWithoutFilter(apiUrls.aboutUs, navigate);
};

export { createHeaders };
