import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import RequestItem from "../components/admin/RequestItem";
import RequestMessage from "../components/admin/RequestMessage";
import { createHeaders, fetchAppealCount } from "../functions/apiService";
import debounce from "lodash/debounce";
import * as consts from "../consts/Consts";
import RequestSearch from "../components/admin/RequestSearch";
import { useDispatch, useSelector } from "react-redux";
import { setDataStatus } from "../store/action"; // Import your action

function Requests({ navigate }) {
  const [requestsData, setRequestsData] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState('');
  const [loadedPages, setLoadedPages] = useState([]);
  const dispatch = useDispatch(); // Hook for dispatching actions

  const SWITCH_OFFER = 0;
  const SWITCH_COMPLAINT = 1;
  const [activeSwitch, setActiveSwitch] = useState(SWITCH_OFFER);

  const dataStatus = useSelector(state => state.appealCount); // Access state from Redux

  const fetchData = async (type, perPage, page, search) => {
    try {
      const response = await fetch(`${consts.API_URL}/admin/appeal?type=${type}&pagination=${perPage}&page=${page}&full_name=${encodeURIComponent(search)}&sort[status]=asc`, {
        method: "GET",
        headers: createHeaders(),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data && data.data ? data.data : [];
    } catch (error) {
      console.error(`Failed to fetch ${type}`, error);
      return [];
    }
  };

  const loadMoreRequests = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    try {
      const type = activeSwitch === SWITCH_COMPLAINT ? 'remark' : 'suggestion';
      const items = await fetchData(type, 5, page, search);

      if (items.length > 0) {
        setRequestsData(prev => [...prev, ...items]);
        setPage(prev => prev + 1);
        setLoadedPages(prev => [...prev, page]);
        if (!selectedRequest) {
          setSelectedRequest(items[0]);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to load requests", error);
    } finally {
      setLoading(false);
    }
  }, [page, hasMore, loading, search, activeSwitch, selectedRequest]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAppealCount(navigate);
        console.log("API Response:", response.count);
        dispatch(setDataStatus(response.count)); // Dispatch action to update Redux state
      } catch (error) {
        console.error("Error fetching appeal count:", error);
      }
    };

    fetchData();
  }, [navigate, dispatch]);

  useEffect(() => {
    loadMoreRequests();
  }, [loadMoreRequests]);

  useEffect(() => {
    setPage(1);
    setRequestsData([]);
    setLoadedPages([]);
    setHasMore(true);
    setSelectedRequest(null);
  }, [search, activeSwitch]);

  const handleScroll = useCallback(
    debounce(() => {
      const bottomReached =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 10;

      if (bottomReached && hasMore && !loading) {
        loadMoreRequests();
      }
    }, 200),
    [hasMore, loading, loadMoreRequests]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const updateRequestStatus = async (id, status) => {
    try {
      const response = await fetch(`${consts.API_URL}/admin/appeal/${id}`, {
        method: "PUT",
        headers: createHeaders(),
        body: JSON.stringify({ status }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to update request status", error);
      throw error;
    }
  };
       

  const handleRequestItemClick = async (request) => {
    setSelectedRequest(request);

    if (request.status === 0) {
      try {
        await updateRequestStatus(request.id, 1);
        setRequestsData(prevRequests =>
          prevRequests.map(req =>
            req.id === request.id ? { ...req, status: 1 } : req
          )
        );
        setSelectedRequest(prev =>
          prev && prev.id === request.id ? { ...prev, status: 1 } : prev
        );

        const updatedDataStatus = await fetchAppealCount(navigate);        
        dispatch(setDataStatus(updatedDataStatus.count));
      } catch (error) {
        console.error("Failed to update request status", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
    setRequestsData([]);
  };


  return (
    <>
      <section className="request">
        <h3 className="title mb-3">Gələn müraciətlər</h3>
        <div className="request__wrapper">
          <Row style={{ height: "100%" }}>
            <Col xs={12} md={4}>
              <div className="request__left d-flex flex-column justify-content-between">
                <div className="switch-container">
                  <div className={`switch-active ${activeSwitch === SWITCH_COMPLAINT ? "right" : ""}`}></div>
                  <div className="switch">
                    <button
                      className={`switch-left switch-side ${activeSwitch === SWITCH_OFFER ? "active" : ""}`}
                      onClick={() => setActiveSwitch(SWITCH_OFFER)}
                    >
                      <div className="s_inner">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          color="currentColor"
                          fill="none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            color="curretnColor"
                            fill="none"
                          >
                            <path
                              d="M6.08938 14.9992C5.71097 14.1486 5.5 13.2023 5.5 12.2051C5.5 8.50154 8.41015 5.49921 12 5.49921C15.5899 5.49921 18.5 8.50154 18.5 12.2051C18.5 13.2023 18.289 14.1486 17.9106 14.9992"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M12 1.99921V2.99921"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 11.9992H21"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3 11.9992H2"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.0704 4.92792L18.3633 5.63503"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.6368 5.636L4.92969 4.92889"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.517 19.3056C15.5274 18.9788 15.9326 18.054 16.0466 17.1238C16.0806 16.8459 15.852 16.6154 15.572 16.6154L8.47685 16.6156C8.18725 16.6156 7.95467 16.8614 7.98925 17.1489C8.1009 18.0773 8.3827 18.7555 9.45345 19.3056M14.517 19.3056C14.517 19.3056 9.62971 19.3056 9.45345 19.3056M14.517 19.3056C14.3955 21.2506 13.8338 22.0209 12.0068 21.9993C10.0526 22.0354 9.60303 21.0833 9.45345 19.3056"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </svg>
                        <span>Təklif</span>
                      </div>
                    </button>
                    <button
                      className={`switch-right switch-side ${activeSwitch === SWITCH_COMPLAINT ? "active" : ""}`}
                      onClick={() => setActiveSwitch(SWITCH_COMPLAINT)}
                    >
                      <div className="s_inner">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          color="currentColor"
                          fill="none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            color="curretnColor"
                            fill="none"
                          >
                            <path
                              d="M8.62814 12.6736H8.16918C6.68545 12.6736 5.94358 12.6736 5.62736 12.1844C5.31114 11.6953 5.61244 11.0138 6.21504 9.65083L8.02668 5.55323C8.57457 4.314 8.84852 3.69438 9.37997 3.34719C9.91142 3 10.5859 3 11.935 3H14.0244C15.6632 3 16.4826 3 16.7916 3.53535C17.1007 4.0707 16.6942 4.78588 15.8811 6.21623L14.8092 8.10188C14.405 8.81295 14.2029 9.16849 14.2057 9.45952C14.2094 9.83775 14.4105 10.1862 14.7354 10.377C14.9854 10.5239 15.3927 10.5239 16.2074 10.5239C17.2373 10.5239 17.7523 10.5239 18.0205 10.7022C18.3689 10.9338 18.5513 11.3482 18.4874 11.7632C18.4382 12.0826 18.0918 12.4656 17.399 13.2317L11.8639 19.3523C10.7767 20.5545 10.2331 21.1556 9.86807 20.9654C9.50303 20.7751 9.67833 19.9822 10.0289 18.3962L10.7157 15.2896C10.9826 14.082 11.1161 13.4782 10.7951 13.0759C10.4741 12.6736 9.85877 12.6736 8.62814 12.6736Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </svg>
                        <span>Şikayət</span>
                      </div>
                    </button>
                  </div>
                </div>

                <Row className="request__list">
                  <div className="request__row">
                    {requestsData.map((request) => (
                      <RequestItem
                        key={request.id}
                        request={request}
                        onClick={() => handleRequestItemClick(request)}
                        selected={selectedRequest && selectedRequest.id === request.id}
                      />
                    ))}
                  </div>
                </Row>
                <div className="request__search">
                  <input
                    type="text"
                    placeholder="Sorğu axtar"
                    className="request-input"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <p className="request__search-icon">
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM16.0319 14.6177C17.2635 13.078 18 11.125 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C11.125 18 13.078 17.2635 14.6177 16.0319L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L16.0319 14.6177Z"
                          fill="#6F767E"
                        />
                      </svg>
                    </svg>
                  </p>
                </div>
              </div>
            </Col>
            {selectedRequest && (
              <RequestMessage selectedRequestData={selectedRequest} />
            )}
             {!selectedRequest && (
              <RequestSearch />
            )}
          </Row>
        </div>
      </section>
    </>
  );
}

export default Requests;
