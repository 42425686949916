import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { graduatesTableHeading, graduatesTableSelect } from "../../utils/data";
import GraduatesTableItem from "./GraduatesTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchGraduates } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function GraduatesTable() {
  const navigate = useNavigate();

  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  
  const [sortField, setSortField] = useState('full_name');  
  const [sortOrder, setSortOrder] = useState('asc'); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchGraduates(navigate, perPage, currentPage, search, sortField, sortOrder);
        setGetData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [perPage, currentPage, search,navigate, sortField, sortOrder]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  const handleSortChange = (value) => {
    const [field, order] = value.split(' '); // e.g., "full_name asc" or "email desc"
    setSortField(field);
    setSortOrder(order);
  };



  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <FilterSelect data={graduatesTableSelect} onSelect={handleSortChange} />

            <div className="search-box">
            <input 
                type="text" 
                placeholder="Axtar" 
                value={search}
                onChange={handleSearchChange} 
              />            
              </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {graduatesTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                      {sortField === item.value && (
                        <span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
                      )}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {getData &&
                getData.map((graduates, i) => (
                  <GraduatesTableItem data={graduates} key={i} />
                ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />

          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default GraduatesTable;
