import { Pagination } from "@mui/material";
import { useState } from "react";
import {
  teachersActivityTable,
  teachersActivityTableHeading,
} from "../../utils/data";
import TeachersActivityTableItem from "./TeachersActivityTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";

function TeachersActivityTable() {
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <FilterSelect data={teachersActivityTableHeading} />

            <div className="search-box">
              <input type="text" placeholder="Axtar" />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {teachersActivityTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div
                      className={`${i === 0 ? "left" : ""} column-content ${
                        i === teachersActivityTableHeading.lengh - 1
                          ? "right"
                          : ""
                      }`}
                    >
                      {item.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {teachersActivityTable.map((item, i) => (
                <TeachersActivityTableItem data={item} key={i} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={19}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />

          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            onClick={() => setOpenSelectTwo((prev) => !prev)}
          >
            <option>5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default TeachersActivityTable;
