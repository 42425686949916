import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import UserProfile from "../UserProfile";

function UserInfo() {
  const navigate = useNavigate();

  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAboutMe(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="user__top  mb-5">
      <div className="user__img">
        {getData.image ? (
          <img src={getData.image} alt="" className="profile-avatar-image profile-image-size" />
        ) : (
          <UserProfile imageSize={90} containerSize={110} />
        )}
      </div>
      <div className="user__info">
        <h3 className="user__name">{getData.full_name}</h3>
        <p className="user__email">{getData.email}</p>
        <p className="user__access">
          <p className="user__access">
            {getData.role === "admin"
              ? "Administrator"
              : getData.role === "teacher"
              ? "Müəllim"
              : getData.role === "student"
              ? "Tələbə"
              : ""}
          </p>
        </p>
      </div>
    </div>
  );
}

export default UserInfo;
