import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { createHeaders, fetchSubjects, fetchTeachersSubjects } from "../functions/apiService";
import TopStudents from "../components/admin/TopStudents";
import ContactCard from "../components/admin/ContactCard";
import * as consts from "../consts/Consts"

function TopStudent() {
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [topStudents, setTopStudents] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTeachersSubjects();
        setSubjects(data);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);


  const getTopStudents = async (subjectId) => {
    const headers = createHeaders();
    const url = subjectId
      ? `${consts.API_URL}/statics/get/top-students?subject_id=${subjectId}`
      : `${consts.API_URL}/statics/get/top-students`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Error fetching top students");
      }

      const data = await response.json();
      setTopStudents(data.data);
    } catch (error) {
      console.error("Error fetching top students:", error);
    }
  };

  useEffect(() => {
    getTopStudents(selectedSubject);
  }, [selectedSubject]);

  return (
    <Col xs={12} md={4} lg={4}>
      <div className="user__right">
        <div className="top">
          <div className="top__head">
            <h3 className="top__label">Top-5 tələbə</h3>
            <div className="top__filter">
              <Form.Select
                className={`selection ${openSelectTwo ? "active" : ""}`}
                onClick={() => setOpenSelectTwo((prev) => !(prev))}
                onChange={(e) => setSelectedSubject(e.target.value)}
              >
                <option value="">Fənn</option>
                {!loading &&
                  subjects?.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="top__list">
            {topStudents.length > 0 ? (
              topStudents.map((studentData) => (
                <TopStudents
                 data={studentData}
                />
              ))
            ) : (
              <p>Bu ixtisasda statistika yoxdur!</p>
            )}
          </div>
        </div>
      </div>

      <ContactCard />
    </Col>
  );
}

export default TopStudent;

